//!!MENU
header[role="banner"]{
	z-index: 50;
	width: 100%;
	transform: translateZ(0);
	perspective: 999999px;
	position: fixed;
	top: 0;
	left: 0;

	@media (max-width: $md){
		
		.pop-open &{
			z-index: 0;
			transition: 0s;
		}
	}
}

body{

	&.scroll-up,
	&.scroll-down{

		header[role="banner"]{

			.menu-top-outer{

				.container{
					transition: .68s;
					backdrop-filter: blur(.2rem);
					background-color: rgba(#F2F2F2,.9);
					margin-top: 6.1rem;
					margin-top: 2rem;
					max-width: 90rem;
					padding-top: .8rem;
					padding-bottom: .8rem;
					border-radius: 3rem;
					padding-right: .8rem;
					padding-left: .8rem;

					@media (max-width: $md){
						max-width: calc(100% - 2rem);
						padding-right: 2rem;
						padding-left: 2rem;
						margin-top: 1rem;
					}
				}

				#site-logo{
					transition: .68s;
					transition: .68s .32s;
					transition: .34s .34s;
					transition: all .34s .34s, filter .34s 0s;
					width: 6.3rem;
					height: 3.23rem;
				}
			}
		}
	}

	&:not(.scroll-up):not(.scroll-down):has(.main-wrapper section.hero-section.black:first-of-type){

		header[role="banner"]{

			#site-logo{
				filter: invert(1) brightness(100);

				.menu-open &{
					filter: invert(0) brightness(1);
				}
			}

			@media (max-width: $md){
				
				.burger:not(.open){

					.line1{
						background-color: $white;
						
						&::before,
						&::after{
							background-color: $white;
						}
					}
				}
			}


			@media (min-width: $md + 1){
				
				#mainMenu{

					.main-menu{
	
						li{
	
							a{
								color: $white;
							}
						}
					}
				}
			}
			
			.accessibility{

				&:hover,
				&:focus-visible{
					
					svg{

						*{
							fill: $blue;
						}
					}
				}

				svg{

					*{
						fill: $white;

						.menu-open &{
							fill: $black;
						}
					}
				}
			}

			.search-con{

				.search{

					&:hover,
					&:focus-visible{
						
						svg{

							*{
								fill: $blue;
							}
						}
					}

					svg{

						*{
							fill: $white;

							.menu-open &{
								fill: $black;
							}
						}
					}
				}
			}
		}
	}
}

#site-logo{
	display: block;
	height: auto;
	margin: 0;
	z-index: 2;
	transition: 0s;
	display: flex;
	align-items: flex-end;
	width: 18.3rem;
	height: 4.2rem;
	transition: $time;
	transition: all .68s .32s, filter .32s .32s;
	position: relative;
	overflow: hidden;

	@media (max-width: $md){
		width: 14.5rem;
		z-index: 3;
		position: relative;
	}
	
	lottie-player{
		aspect-ratio: 183/41;
		object-fit: cover;
		object-position: 50% 50%;
		width: 18.3rem;
		max-width: 18.3rem;
		min-width: 18.3rem;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		transition: all .68s .32s, filter .32s .32s;
		padding: .15rem;

		@media (max-width: $md){
			width: 14.5rem;
			max-width: 14.5rem;
			min-width: 14.5rem;
			opacity: 0;
			visibility: hidden;
		}
	}

	.mob-logo{
		width: 3.1rem;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0rem;

		@media (min-width: $md + 1){
			display: none;
		}
	}
}

.menu-top-outer{
	transition: $time;

	@media (max-width: $md){
		
		.menu-open &{

			.container{
				position: relative;

				&:before{
					opacity: 1;
					visibility: visible;
					transition: $time $time;
				}
			}
		}
	}

	.container{
		padding-top: 2.6rem;
		padding-bottom: 2.6rem;
		transition: .68s .32s;
		backdrop-filter: blur(0);
		background-color: rgba(#F2F2F2,0);

		@media (max-width: $md){
			padding-top: 1rem;
			padding-bottom: 1rem;
		}
	}
	
	.col-12{
		display: flex;
		align-items: center;
		justify-content: space-between;

		.left,
		.right{
			display: flex;
			align-items: center;
		}

		.left{
			justify-content: flex-start;
			width: 18.3rem;
		}

		.right{
			justify-content: flex-end;
			width: calc(100% - 18.3rem);
		}

		.accessibility-menu{
			position: absolute;
			top: calc(100% + 1.4rem);
			right: 1rem;
			width: 90.2rem;
			backdrop-filter: blur(0);
			background-color: rgba(#F2F2F2,.9);
			padding: 1.2rem 2.1rem;
			border-radius: 1rem;
			display: flex;
			justify-content: space-between;
			transition: $time;
			opacity: 0;
			visibility: hidden;
			pointer-events: none;

			&.open{
				opacity: 1;
				visibility: visible;
				pointer-events: auto;
			}

			@media (min-width: $md + 1){
				
				.scroll-down &,
				.scroll-up &{
					right: 0rem;
				}
					
			}

			@media (max-width: $md){
				
				.scroll-down &,
				.scroll-up &{
					left: -1.5rem;
					width: calc(100% + 1.5rem + 1.5rem);
					max-width: calc(100% + 1.5rem + 1.5rem);
				}
			}

			.a-item{
				text-decoration: none;
				display: flex;
				align-items: center;
				font-weight: 500;

				&[data-accessibility="adhd-friendly"],
				&[data-accessibility="large-cursor"]{

					@media (max-width: $md){
						display: none;
					}
				}

				&.active{

					.ticker{
						background-color: $green;

						&:after{
							transform: translateX(2rem) translateY(-50%);
						}
					}
				}

				.ticker{
					width: 4.8rem;
					height: 2.8rem;
					background-color: $black;
					border-radius: 3rem;
					margin-left: 1.2rem;
					position: relative;
					transition: $time;

					&:after{
						content: '';
						position: absolute;
						top: 50%;
						left: .4rem;
						width: 2rem;
						height: 2rem;
						background-color: #F2F2F2;
						border-radius: 2rem;
						transform: translateX(0) translateY(-50%);
						transition: $time;
					}
				}

			}
		}

		button.accessibility{
			width: 3.5rem;
			min-width: 3.5rem;
			aspect-ratio: 1/1;
			border-radius: 3.5rem;
			margin-left: 3.4rem;
			position: relative;

			&:after{
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				background-color: $black;
				width: 90%;
				height: 90%;
				border-radius: 100%;
				z-index: -1;
				opacity: 0;
				transition: $time;
			}

			@media (max-width: $md){
				order: 1;
				margin-left: 0;
			}

			&.open,
			&:hover,
			&:focus-visible{

				&:after{
					opacity: 1;
				}

				*{
					fill: $blue;
				}
			}

			svg{
				width: 100%;

				*{
					fill: $black;
				}
			}
		}

		.search-con{
			width: 3.5rem;
			min-width: 3.5rem;
			height: 3.5rem;
			margin-left: 1.2rem;
			z-index: 2;

			@media (max-width: $md){
				margin-left: 0;
				order: 2;
				top: 8.5rem;
				position: absolute;
				left: 7.9rem;
			}

			&.open{

				.search-form{
					// @media (max-width: $md){
					// 	max-width: calc(100vw - 4rem);

					// 	.scroll-down &,
					// 	.scroll-up &{
					// 		max-width: calc(100vw - 6rem);
					// 	}
					// }

					// [type="text"]{
						
					// 	@media (min-width: $md + 1){
					// 		padding-right: 4.5rem;
					// 	}

					// 	@media (max-width: $md){
					// 		padding-left: 4.5rem;
					// 	}
					// }
				}
			}

			button.search{
				width: 3.5rem;
				aspect-ratio: 1/1;
				border-radius: 3.5rem;
				position: relative;
				z-index: 2;

				&:after{
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					background-color: $black;
					width: 90%;
					height: 90%;
					border-radius: 100%;
					z-index: -1;
					opacity: 0;
					transition: $time;
				}

				&.open,
				&:hover,
				&:focus-visible{

					&:after{
						opacity: 1;
					}
					
					*{
						fill: $blue;
					}
				}

				svg{
					width: 100%;

					*{
						fill: $black;
					}
				}
			}
		}
	}
}

.search-form-con{
	position: fixed;
	top: 0;
	left: 0;
	width: 100dvw;
	height: 100svh;
	background-color: #F2F2F2;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 49;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	transition: $time;

	&.open{
		opacity: 1;
		visibility: visible;
		pointer-events: auto;
	}

	.search-form{
		position: relative;
		width: 90rem;
		transition: $time;
		overflow: hidden;

		@media (max-width: $md){
			right: auto;
			left: 0;
			width: calc(100vw - 4rem);
		}

		::-webkit-input-placeholder{
			@include font-size(56);
			color: $form;
		
			@media (max-width: $md){
				@include font-size(24);
			}
		}
		 
		:-moz-placeholder{ /* Firefox 18- */
			@include font-size(56);
			color: $form;
		
			@media (max-width: $md){
				@include font-size(24);
			}
		}
		
		::-moz-placeholder{  /* Firefox 19+ */
			@include font-size(56);
			color: $form;
		
			@media (max-width: $md){
				@include font-size(24);
			}
		}
		
		:-ms-input-placeholder{
			@include font-size(56);
			color: $form;
		
			@media (max-width: $md){
				@include font-size(24);
			}
		}

		[type="text"]{
			border: .2rem solid $black;
			border-top: none;
			border-right: none;
			border-left: none;
			border-radius: 0;
			padding-top: 0;
			padding-bottom: 0;
			padding-right: 0rem;
			@include font-size(56);
			padding: 0.2rem 6rem 0.2rem 0;

			@media (max-width: $md){
				@include font-size(24);
				padding: 1.7rem 6rem 1.7rem 0;
			}
		}

		button{
			width: 5rem;
			min-width: 5rem;
			aspect-ratio: 1/1;
			border-radius: 5rem;
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			background-color: $blue;
			padding: 0;
			border-color: $blue;

			&:hover,
			&:focus-visible{
				background-color: $black;
				border-color: $black;

				*{
					fill: $white;
				}
			}

			*{
				fill: $black;
				width: 3.2rem;
			}
		}
	}
}

.mob-only.mob-only{
	width: 100%;

	@media (min-width: $md + 1){
		display: none;
	}

	.social-links{
		justify-content: center;
	}
}

@import 'burger';

#mainMenu{
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	@media (min-width: $md + 1){
		flex-wrap: nowrap;
	}

	@media (max-width: $md){
		margin: 0 auto;
		position: fixed;
		top: 0rem;
		left: -1.4rem;
		width: calc(100% + 1.4rem + 1.4rem);
		max-width: calc(100% + 1.4rem + 1.4rem);
		// transition: $time;
		// padding: 0rem 2rem;
		flex-direction: column;
		flex-wrap: nowrap;
		display: flex;
		justify-content: flex-start;
		// overflow: hidden;
		z-index: -10;
		max-height: 100dvh;
		height: 100dvh;
		background-color: $white;
		transform: translateX(100%);
		transition: .68s;
		padding: 8.5rem 3.5rem 1rem 3.5rem;
		align-items: flex-start;

		.menu-open &{
			transform: translateX(0%);
		}

		.scroll-up &,
		.scroll-down &{
			top: -1rem;
		}
	}
}

@media (min-width: $md + 1){

	// #mainMenu .main-menu .current-menu-ancestor:not(.menu-item-object-custom),
	// #mainMenu .main-menu .current-page-ancestor:not(.menu-item-object-custom),
	// #mainMenu .main-menu .current-menu-parent:not(.menu-item-object-custom),
	// #mainMenu .main-menu .current_page_parent:not(.menu-item-object-custom),
	// #mainMenu .main-menu .current-menu-item:not(.menu-item-object-custom),
	#mainMenu .main-menu .current-menu-ancestor,
	#mainMenu .main-menu .current-page-ancestor,
	#mainMenu .main-menu .current-menu-parent,
	#mainMenu .main-menu .current_page_parent,
	#mainMenu .main-menu .current-menu-item{

		> a{
			
			&:after{
				opacity: 1;
				visibility: visible;
			}
		}
	}
}

ul.main-menu.main-menu{
	margin: 0 0;
	padding: 0;
	@include font-size(0);
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;

	@media (min-width: $md + 1){
		flex-wrap: nowrap;
	}

	@media (max-width: $md){
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		counter-reset: section;
		max-height: calc(100dvh - 12.1rem);
		overflow: auto;
		flex-wrap: nowrap;
		justify-content: flex-start;
		margin-top: 2.63rem;
		order: 3;
	}
	
	li{
		@include font-size(0);
		line-height: 1;
		position: static;
		display: flex;
		text-align: center;

		@media (min-width: $md + 1){

			&.green{

				> a:after{
					background-color: $green;
				}
			}
		
			&.yellow{
		
				> a:after{
					background-color: $yellow;
				}
			}
		
			&.blue{
		
				> a:after{
					background-color: $blue;
				}
			}
		
			&.purple{
		
				> a:after{
					background-color: $purple;
				}
			}
		
			&.pink{
		
				> a:after{
					background-color: $pink;
				}
			}

			+ li{
				margin-left: 3.1rem;
			}

			&:hover,
			&:focus-visible{
				
				> a{
					color: $black;
					
					&:after{
						opacity: 1;
						visibility: visible;
					}
				}
			}

			&.mob-back{
				display: none;
			}
		}

		@media (max-width: $md){
			margin: 0 0;
			justify-content: left;
			text-align: left;
			position: relative;
			border-bottom: .1rem solid $black;
			width: 100%;

			&:last-of-type{
				border-bottom: none;
			}
	
			&:last-of-type{
				margin-bottom: 0;
			}
			
			&.open{

				> .sub-menu.sub-menu{
					opacity: 1;
					visibility: visible;
					pointer-events: auto;
					max-height: 9999px;
					margin-bottom: 0rem;
				}
			}
		}

		&.no-link{

			> a{
				pointer-events: none;
			}
		}

		&.menu-item-has-children{
			flex-wrap: wrap;
			position: relative;

			
			@media (min-width: $md + 1){

				[arial-label="sub menu"]{

					&:focus{
	
						+ .sub-menu{
							opacity: 1;
							visibility: visible;
							pointer-events: auto;
						}
					}
				}

				.sub-menu:has(*:focus){
					opacity: 1;
					visibility: visible;
					pointer-events: auto;
				}

				&:hover,
				&:focus-visible{
					
					> .sub-menu{
						opacity: 1;
						visibility: visible;
						pointer-events: auto;
					}
				}

				li{

					&.green a{

						&:hover,
						&:focus-visible{
							background-color: $green;
						}
					}
				
					&.yellow a{
				
						&:hover,
						&:focus-visible{
							background-color: $yellow;
						}
					}
				
					&.blue a{
				
						&:hover,
						&:focus-visible{
							background-color: $blue;
						}
					}
				
					&.purple a{
				
						&:hover,
						&:focus-visible{
							background-color: $purple;
						}
					}
				
					&.pink a{
				
						&:hover,
						&:focus-visible{
							background-color: $pink;
						}
					}
				}
			}

			@media (max-width: $md){
				padding-right: 4.3rem;
				position: relative;
				width: 100%;

				&:before{
					content: '';
					position: absolute;
					width: 1.7rem;
					right: 0.6rem;
					top: 2.6rem;
					height: .2rem;
					border-radius: .2rem;
					background-color: $black;
					transform: rotate(90deg);
					transition: $time;
					display: block!important;
					left: auto;
				}

				&:after{
					content: '';
					position: absolute;
					width: 1.7rem;
					right: 0.6rem;
					top: 2.6rem;
					height: .2rem;
					border-radius: .2rem;
					background-color: $black;
					transform: rotate(0deg);
					transition: $time;
					display: block!important;
				}

				&.open{

					&:before{
						transform: rotate(180deg);
					}
				}
			}

			.sub-menu{
				position: absolute;
				top: 100%;
				left: 0;
				width: auto;
				max-width: none;
				pointer-events: none;
				opacity: 0;
				visibility: hidden;
				min-width: 11rem;

				@media (min-width: $md + 1){
					padding: 1.6rem 0 0;
					z-index: 1;

					&:after{
						content: '';
						position: absolute;
						top: 1.6rem;
						left: 0;
						bottom: 0;
						right: 0;
						background-color: $white;
						border-radius: 1rem;
						box-shadow: 0 0 1rem rgba($black,.1);
						z-index: -1;
					}

					> li{

						&:first-of-type{
							margin-top: .6rem;
						}

						&:last-of-type{
							margin-bottom: .6rem;
						}
					}
				}

				@media (max-width: $md){
					position: static;
					transform: none;
					max-height: 0;
					overflow: hidden;
					width: calc(100% + 4.3rem);
					max-width: calc(100% + 4.3rem);
					background-color: transparent;
					border-radius: 0;
					margin-right: -4.3rem;
					margin-top: -1.4rem;
					padding-bottom: .8rem;

					.sub-menu{
						margin-top: 0rem;
						padding-left: 2.8rem;
						width: calc(100% + 2.6rem);
					}
				}

				li{
					width: 100%;
					text-align: left;
					margin: 0;
					justify-content: flex-start;

					@media (max-width: $md){
						width: 100%;
						text-align: left;
						border-bottom: none;

						&:last-of-type{
							margin-bottom: 0;
						}
					}

					&.menu-item-has-children{
						padding-right: 2.6rem;
						background-image: url(../images/menu-arrow.svg);
						background-repeat: no-repeat;
						background-position: right 1.5rem top 50%;
						background-size: 1.1rem auto;

						@media (max-width: $md){
							background-image: none;

							&:before,
							&:after{
								top: 1.8rem;
							}
						}



						> a{
							padding-right: .9rem;
						}
					}

					.sub-menu{
						
						@media (min-width: $md + 1){
							top: -.5rem;
							left: 100%;
							padding-top: 0;
							padding-left: .8rem;

							&:after{
								top: 0rem;
								left: .8rem;
							}

						}
					}
				}

				a{
					@include font-size(14);
					@include line-height(14,18.23);
					font-weight: 400;
					color: $black;
					padding: .25rem 0;
					width: auto;
					margin: 0;
					padding: .6rem 2rem .6rem 2rem;
					white-space: nowrap;
					text-align: left;
					display: block;

					@media (max-width: $md){
						@include font-size(16);
						padding: 0.8rem 2rem 0.8rem 2.7rem;
					}

					
					@media (min-width: $md + 1){
						color: $black!important;
						
						&:hover,
						&:focus-visible{
							color: $blue!important;
						}
					}

					&:after{
						display: none;
					}
				}
			}
		}

		[href="#"]{
			pointer-events: none;
		}

		[target="_blank"]{
			background-image: url(../images/external.png);
			background-repeat: no-repeat;
			background-size: 1.2rem auto;
			background-position: right .2rem top 50%;
		}

		> a{
			@include font-size(20);
			@include font-size(16);
			line-height: 1.4;
			width: auto;
			color: $black;
			text-decoration: none!important;
			font-weight: 500;
			position: relative;
			font-family: $alt-font;
			padding: .5rem 0;
			z-index: 2;
			display: flex;
			justify-content: center;
			align-items: center;

			@media (min-width: $md + 1){

				&:after{
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					height: .2rem;
					background-color: $black;
					opacity: 0;
					visibility: hidden;
				}
				
				&:hover,
				&:focus-visible{
					color: $black;
					
					&:after{
						opacity: 1;
						visibility: visible;
					}
				}
			}

			@media (max-width: $md){
				align-items: flex-start;
				@include font-size(20);
				@include font-size(16);
				padding: 1.5rem 0;

				span{
					transition: 0s;
				}
				
				&:hover,
				&:focus-visible{
					color: $black;

					span{
						text-decoration: underline;
					}
				}
			}
		}
	}
}


// .single-sectors,
// .single-services{

// 	.main-menu{

// 		#menu-item-11{

// 			> a{

// 				&:after{
// 					opacity: 1;
// 					visibility: visible;
// 				}
// 			}
// 		}
// 	}
// }