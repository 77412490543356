footer{
	position: relative;
	z-index: 3;

	// .container{

	// 	@media (min-width: $md + 1){
	// 		max-width: calc(100% - 6.2rem - 6.2rem + 2.4rem);
	// 	}
	// }

	.footer-top-outer{
		background-color: $black;

		*{
			color: $white;
		}

		.container{
			padding-top: 10.7rem;
			padding-bottom: 0rem;

			@media (max-width: $md){
				padding-top: 1.9rem;
			}
		}

		h3{

			@media (max-width: $md){
				letter-spacing: -.02em;
				@include font-size(24);
				@include line-height(24,28);
			}
		}

		.btn{
			background-color: $yellow;
			border-color: $yellow;
			color: $black!important;
			transform: translateY(-1.3rem);
			margin-bottom: -1.3rem;

			@media (min-width: $md + 1){
				transform: translateY(-.3rem);
			}

			@media (max-width: $md){
				position: absolute;
				top: 2.2rem;
				right: .5rem;
			}

			&:hover,
			&:focus-visible{
				background-color: $black;
				border-color: $yellow;
				color: $white!important;
			}
		}
	}

	.footer-middle-outer{
		background-color: $black;

		*{
			color: $white;
		}

		.container{
			padding-top: 13.6rem;

			@media (max-width: $md){
				padding-top: 5.4rem;
			}
		}

		.row{
			align-items: flex-end;
		}

		h6{
			font-weight: 500;
			margin-bottom: .04em;

			@media (max-width: $md){
				@include font-size(15);
			}

			~ div{
				@include font-size(18);
				@include line-height(18,23);

				@media (max-width: $md){
					@include font-size(13);
				}
			}

			~ h6{
				margin-top: .7em;
			}
		}

		a[href^="tel:"],
		a[href^="mailto:"]{
			@include font-size(18);
			@include line-height(18,23);
			text-decoration: none;

			@media (max-width: $md){
				@include font-size(13);
			}

			&:hover,
			&:focus-visible{
				color: $yellow;
			}
		}

		.copy{
			@include font-size(18);
			@include line-height(18,23);
			margin-bottom: .8em;

			@media (max-width: $md){
				@include font-size(13);
			}
		}

		.col-md-4{

			@media (max-width: $md){
				margin-bottom: 4rem;

				&:nth-last-of-type(2){
					margin-bottom: 2.1rem;
				}

				&:last-of-type{
					margin-bottom: 0rem;
				}

				.social-menu{
					justify-content: flex-start;
				}
			}

			&:has(.footer-menu){

				@media (min-width: $md + 1){
					padding-left: 2.5rem;
				}
			}

			.social-menu{

				a{
					color: #bababa;

					*{
						color: #bababa;
					}

					&:hover,
					&:focus-visible{
						color: $yellow;

						*{
							color: $yellow;
						}
					}
				}
			}
		}

		.footer-menu{
			@include font-size(18);
			@include line-height(18,23);
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 0;

			@media (max-width: $md){
				@include font-size(13);
			}

			li{
				@include font-size(18);
				@include line-height(18,23);

				@media (max-width: $md){
					@include font-size(13);
				}

				&:has(+ li){
					margin-right: .6rem;

					&:after{
						content: '|';
						display: inline-block;
						margin-left: .6rem
					}
				}
			}

			a{
				@include font-size(18);
				@include line-height(18,23);
				width: auto;
				color: $white;
				letter-spacing: -.01em;
				font-weight: 400;
				position: relative;
				font-family: $main-font;
				padding: 0;
				text-decoration: none;

				@media (max-width: $md){
					@include font-size(13);
				}

				&:hover,
				&:focus-visible{
					color: $yellow;
				}
			}
		}
	}

	.footer-bottom-outer{
		background-color: $black;
		padding-top: 6rem;

		svg{
			margin: 0 auto;
			width: calc(100% - 3rem);

			@media (max-width: $md){
				width: 100%;
			}
		}

	}
}