//!!BUTTON
button:focus-visible,
.btn:focus-visible{
	outline: none;
}

.btn-con{
	display: flex;
	align-items: center;
	margin: 0 -.85rem;
	max-width: calc(100% + .85rem + .85rem);
	flex-wrap: wrap;

	@media (max-width: $md){
		margin: 0 -.6rem;
		max-width: calc(100% + .6rem + .6rem);
	}

	.arrow-link,
	.btn{
		margin: 0 .8rem .85rem;

		@media (max-width: $md){
			margin: 0 .6rem 1.7rem;
		}

		&:first-of-type:last-of-type{
			margin-bottom: 0;
		}
	}

	&.btn-con-center{
		justify-content: center;
	}

	&.btn-con-left{
		justify-content: flex-start;
	}

	&.btn-con-right{
		justify-content: flex-end;
	}
}

.btn, %btn{
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	font-size: 2rem!important;
	line-height: 1.3;
	font-weight: 500;
	color: $colour!important;
	text-decoration: none!important;
	font-family: $main-font;
	text-align: center;
	padding: 1.05rem 1.7rem;
	background-color: $transparent;
	border: .2rem solid $black;
	border-radius: 2.5rem;
	box-shadow: none;
	letter-spacing: 0;
	cursor: pointer;
	position: relative;
	transition: $time;
	z-index: 2;
	justify-content: center;

	@media (max-width: $md){
		font-size: 1.6rem!important;
		padding: .87rem 1.7rem;
	}

	&:hover,
	&:focus-visible{
		outline: none;
		background-color: $black;
		border-color: $black;
		color: $white!important;
	}

	&.disabled,
	&:disabled{
		opacity: .25;
		pointer-events: none;
	}
}

.btn{

	&.green{

		&:hover,
		&:focus-visible{
			background-color: $green;
			color: $black!important;
		}
	}

	&.yellow{

		&:hover,
		&:focus-visible{
			background-color: $yellow;
			color: $black!important;
		}
	}

	&.blue{

		&:hover,
		&:focus-visible{
			background-color: $blue;
			color: $black!important;
		}
	}

	&.purple{

		&:hover,
		&:focus-visible{
			background-color: $purple;
			color: $black!important;
		}
	}

	&.pink{

		&:hover,
		&:focus-visible{
			background-color: $pink;
			color: $black!important;
		}
	}
 
	&.small{
		font-size: 1.6rem!important;
	}

	&.big{
		font-size: 3.2rem!important;
		padding: .45rem 1.1rem;
		font-weight: 400;
		border-radius: 2.8rem;

		@media (max-width: $md){
			font-size: 2rem!important;
			padding: .75rem 1.6rem;
			letter-spacing: -.02em;
		}
	}
}