//!!SCALE SETTINGS
$scale: true; //false, true
$vw-viewport: 1440; //If false does nothing. if true set this to the width of the design sent over

//!!GRID SETTINGS
$grid-gutter-widthWO: 20!default; //most common gutter width

//!!PAGE WIDTH
$base-widthWO:		1360; //most common container width

//!!COLOURS
$transparent:		transparent;

$red:				#d40000;

$green:				#16B988;

$yellow:			#FFC700;

$blue:				#249CFD;

$purple:			#9488FF;

$pink:				#FFBFB8;
$pink-dark:			#FF20DB;

$grey:				#EDEDED;
$grey-dark:			#D9D9D9;

$form:				#878787;

$gold:				#F7C945;
$silver:			#CCCCCC;
$bronze:			#EAB187;

$white:				#FFFFFF;
$black:				#212121;
 
$colour: 			$black;
$main-bg-colour:	$white;

//!!FONTS

@font-face {
	font-family: 'DM Sans';
	src: url('../fonts/DMSans-Regular.woff2') format('woff2'),
		url('../fonts/DMSans-Regular.woff') format('woff'),
		url('../fonts/DMSans-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'DM Sans';
	src: url('../fonts/DMSans-Medium.woff2') format('woff2'),
		url('../fonts/DMSans-Medium.woff') format('woff'),
		url('../fonts/DMSans-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'DM Sans';
	src: url('../fonts/DMSans-Bold.woff2') format('woff2'),
		url('../fonts/DMSans-Bold.woff') format('woff'),
		url('../fonts/DMSans-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}





$font-sans:			'DM Sans', sans-serif;
//regular - 400
//medium - 400
//bold - 700

$main-font:			$font-sans;
$alt-font:			$font-sans;

//!!SITE TRANSITIONS
$time:				.3s;

//BREAK POINTS
$xl:				$base-widthWO - 1px ; //$base-width + - 1px
$lg:				992px; //992px - 1px
$md:				769px - 1px; //769px - 1px
$sm:				576px - 1px; //576px - 1px

//xl: $base-width
//lg: 992px
//md: 768px
//sm: 576px