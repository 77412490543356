//---------------------------------------------------------//
@import 'normalize'; /* site wide variables (colours, font sizes) */
@import 'variables'; /* site wide variables (colours, fonts) */
@import 'toolkit'; /* mixin (@include font-size(??); , @include svg_img("../images/??");) and common classes (.clearfix, .img-replacment) */
@import 'general-tags-and-classes';
@import 'grid'; /* adapted boostap 4 grid*/
@import 'header';
@import 'footer';
@import 'pagination';
//@import 'breadcrumb';
@import 'social';
@import 'buttons';
@import 'forms';
@import 'swiper';
//@import 'fancybox';
//@import 'tabs';

.waypoint{
	transition: .2s;
	opacity: 0;
	//visibility: hidden;
	transform: translateY(5.0rem);
	will-change: transform;

	&.animate{
		opacity: 1;
		//visibility: visible;
		transform: translateY(0);
		transition: .5s .1s;
	}
}

//
//!!BLOCKS START
//

body section{

	&.black{
		background-color: $black;

		*{
			color: $white;
		}

		.btn{
			color: $white!important;
			border-color: $white;

			&:hover,
			&:focus-visible{
				color: $black!important;
				background-color: $yellow;
				border-color: $yellow;
			}
		}

		input,
		input.input-text,
		textarea,
		.fake-select,
		select{
			border-color: $white;
		}

		input[type="radio"],
		input[type="checkbox"]{

			& + span,
			& + .wpcf7-list-item-label,
			& + label{

				&:before{
					border-color: $white;
				}

				&:after{
					border-color: $white;
					background-image: url(../images/tick-yellow.svg);
				}
			}
		}

		// ::-webkit-input-placeholder{
		// 	color: $black;
		// }
		 
		// :-moz-placeholder{ /* Firefox 18- */
		// 	color: $black;
		// }
		
		// ::-moz-placeholder{  /* Firefox 19+ */
		// 	color: $black;
		// }
		
		// :-ms-input-placeholder{
		// 	color: $black;
		// }

		legend, 
		label{

			.gfield_required{

				&::before{
					color: $white;
				}
			}
		}

		*[type="submit"], 
		.submit-con [type="submit"]{

			&:hover,
			&:focus-visible{
				border-color: $yellow;
			}
		}

	}

	&.white{
		background-color: $main-bg-colour;

		.dark-contrast &{
			background-color: #737373!important;

			*{
				color: white!important;
				border-color: $white!important;
			}
		}
	}

	&.yellow{
		background-color: $yellow;
	}

	&.grey{
		background-color: $grey;

		.dark-contrast &{
			background-color: #5a5a5a!important;

			*{
				color: white!important;
				border-color: $white!important;
			}
		}
	}

	.container{
		position: relative;
		z-index: 3;
	}

	*:last-child{
		margin-bottom: 0;
	}

	.btn-con{
		margin-top: 4.3rem;

		@media (max-width: $md){
			margin-top: 2.8rem;
		}
	}
}

main section{
	position: relative;

	@for $i from 1 through 20{

		&:nth-of-type(#{$i}):not(.hero-section){
			z-index: $i;
		}
	}

	+ section{
		margin-top: -2.5rem;
		border-radius: 2.5rem 2.5rem 0 0;
		background-color: $main-bg-colour;

		@media (max-width: $md){
			margin-top: -1.5rem;
			border-radius: 1.5rem 1.5rem 0 0;
		}
	}
}

.share-link.share-link{
	display: inline-flex;
	align-items: center;
	margin: 0 .85rem .85rem;
	text-decoration: none;
	margin-left: 2rem;
	@include font-size(20);
	font-weight: 500;

	@media (max-width: $md){
		@include font-size(13);
	}

	&:hover,
	&:focus-visible{
		color: $green;

		i{

			svg{

				*{
					fill: $green;
				}
			}
		}
	}

	i{
		margin-right: .9rem;

		&:before{
			transition: 0s 0s!important;
		}
	}
}

.hero-section-spacer,
.hero-section{

	.single-careers.single-careers.single-careers.single-careers.single-careers.single-careers &{

		.row{
			padding-top: 23.197rem;
			padding-bottom: 10rem;

			@media (max-width: $md){
				padding-top: 12.697rem;
				padding-bottom: 6rem;
			}

			.btn-con{
				margin-top: 3.4rem;
			}
		}
	}
}

.hero-section:has(+ .hero-section-spacer .text.animate){

	.waypoint,
	.text.waypoint{
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
		transition: .5s .1s;
	}
}

.hero-section-spacer,
.hero-section{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 0;


	.btn-con-center{
		margin-top: 0rem;
	}

	&.remove-overlap{
		position: relative;

		+ .hero-section-spacer{
			display: none;
		}
	}

	.col-12{
		position: static;
	}

	.hero-menu{
		position: absolute;
		top: -11.1rem;
		top: 10.1rem;
		left: 1rem;
		@include font-size(14);
		display: flex;
		align-items: center;

		&.child-pages{
			top: auto;
			position: relative;
			justify-content: center;
		}

		&.parent-menu{

			.sub-menu{
				order: 2;
				display: flex;
				align-items: center;

				li{

					&#menu-item-333{

						a{
							background-color: $yellow;
							border-color: $yellow;
							color: $black!important;
							pointer-events: none;
						}
					}

					ul{
						display: none;
					}

					&:nth-of-type(1) > a{

						&.active,
						&:hover,
						&:focus-visible{
							background-color: $blue;
							border-color: $blue;
							color: $black!important;
						}
					}
	
					&:nth-of-type(2) > a{
	
						&.active,
						&:hover,
						&:focus-visible{
							background-color: $purple;
							border-color: $purple;
							color: $black!important;
						}
					}
	
					&:nth-of-type(3) > a{
	
						&.active,
						&:hover,
						&:focus-visible{
							background-color: $yellow;
							border-color: $yellow;
							color: $black!important;
						}
					}
	
					&:nth-of-type(4) > a{
	
						&.active,
						&:hover,
						&:focus-visible{
							background-color: $green;
							border-color: $green;
							color: $black!important;
						}
					}
	
					&:nth-of-type(5) > a{
	
						&.active,
						&:hover,
						&:focus-visible{
							background-color: $pink;
							border-color: $pink;
							color: $black!important;
						}
					}

					// &:nth-of-type(6),
					// &:nth-of-type(6) + a{
					// 	display: none;
					// }
				}
			}

			.btn{

				&:nth-of-type(1){

					&.active,
					&:hover,
					&:focus-visible{
						background-color: $blue;
						border-color: $blue;
						color: $black!important;
					}
				}

				&:nth-of-type(2){

					&.active,
					&:hover,
					&:focus-visible{
						background-color: $purple;
						border-color: $purple;
						color: $black!important;
					}
				}

				&:nth-of-type(3){

					&.active,
					&:hover,
					&:focus-visible{
						background-color: $yellow;
						border-color: $yellow;
						color: $black!important;
					}
				}

				&:nth-of-type(4){

					&.active,
					&:hover,
					&:focus-visible{
						background-color: $green;
						border-color: $green;
						color: $black!important;
					}
				}

				&:nth-of-type(5){

					&.active,
					&:hover,
					&:focus-visible{
						background-color: $pink;
						border-color: $pink;
						color: $black!important;
					}
				}

				// &:nth-of-type(6),
				// &:nth-of-type(6) + a{
				// 	display: none;
				// }
			}
		}

		@media (max-width: $md){
			display: none;
		}

		.back,
		span{
			font-weight: 500;
			padding: 1.4rem 1.7rem 1.4rem 0;
			border-right: .1rem solid $black;
			order: 1;
			text-decoration: none;
		}

		.back{

			&:hover,
			&:focus-visible{
				color: $blue;
			}
		}

		.btn.small{
			font-size: 1.4rem !important;
			margin-left: 1.5rem;
			padding: .4rem .7rem;
			order: 1;

			&.green{

				&.active,
				&:hover,
				&:focus-visible{
					background-color: $green;
					border-color: $green;
					color: $black!important;
				}
			}
		
			&.yellow{
		
				&.active,
				&:hover,
				&:focus-visible{
					background-color: $yellow;
					border-color: $yellow;
					color: $black!important;
				}
			}
		
			&.blue{
		
				&.active,
				&:hover,
				&:focus-visible{
					background-color: $blue;
					border-color: $blue;
					color: $black!important;
				}
			}

			&.grey{
		
				&.active,
				&:hover,
				&:focus-visible{
					background-color: $grey-dark;
					border-color: $grey-dark;
					color: $black!important;
				}
			}
		
			&.purple{
		
				&.active,
				&:hover,
				&:focus-visible{
					background-color: $purple;
					border-color: $purple;
					color: $black!important;
				}
			}

			&.pink{
		
				&.active,
				&:hover,
				&:focus-visible{
					background-color: $pink;
					border-color: $pink;
					color: $black!important;
				}
			}

			&.active{
				pointer-events: none;
				order: 1;
			}
		}
	}

	&.sectors{



		.single-resources &,
		.single-sectors &{

			&:has(.child-pages){

				+ .hero-section-spacer .row,
				.row{
					padding-bottom: 10.6rem;

					@media (max-width: $md){
						padding-bottom: 3.6rem;
					}
				}
			}

			.row{
				padding-top: 18.597rem;
				padding-bottom: 5.6rem;
				min-height: 54.2rem;
				
				h2{
					width: 91.7rem;
				}

				@media (max-width: $md){
					padding-top: 8rem;
					padding-bottom: 3.6rem;
					min-height: 33.5rem;

					h2{
						width: 34.7rem;
					}
				}
			}
		}

		.row{
			padding-top: 18.597rem;
			padding-bottom: 8.4rem;
			min-height: 64.7rem;

			@media (max-width: $md){
				padding-top: 8rem;
				padding-bottom: 4.4rem;
				min-height: 0rem;

				h2{
					width: 31.7rem;
				}
			}
		}
	}

	&.services{

		.row{
			padding-top: 18.597rem;
			min-height: 39.1rem;


			@media (max-width: $md){
				padding-top: 8rem;
				padding-bottom: 3.6rem;
				min-height: 19.4rem;

				.single-services &{
					padding-bottom: 2.6rem;
					min-height: 22.6rem;
				}

				h2{
					width: 31.7rem;
				}
			}
		}
	}

	&:not(.sectors):not(.services):has(.hero-menu){

		.row{
			padding-top: 18.597rem;

			@media (min-width: $md + 1){
				padding-bottom: 9.197rem;
			}

			@media (max-width: $md){
				padding-top: 8.597rem;
			}
		}
	}

	&:has(+ .download-form-section),
	&:has(+ .hero-section-spacer + .download-form-section){
		
		.row{
			padding-top: 11.797rem;
			min-height: 55.2rem;

			@media (max-width: $md){
				padding-top: 7.397rem;
				min-height: 34.4rem;
			}

			.text{

				h2{
					width: 80rem;

					@media (max-width: $md){
						width: 29rem;
					}
				}
			}
		}
	}
	
	.search-results &,
	.post-type-archive-insight &,
	.post-type-archive-news &{

		.row{
			padding-top: 11.797rem;
			min-height: 48.2rem;

			@media (max-width: $md){
				padding-top: 7.797rem;
				min-height: 34.1rem;
				padding-bottom: 6.2rem;
			}
		}

		.text{

			h2{
				width: 103.7rem;

				@media (max-width: $md){
					width: 32rem;
				}
			}
		}
	}

	.single-careers &{

		.row{
			padding-bottom: 5.7rem;
			min-height: 59.9rem;

			@media (max-width: $md){
				min-height: 48.9rem;
				padding-bottom: .6rem;
			}
		}

		.text{

			h2{
				margin-bottom: .34em;

				@media (max-width: $md){
					width: 31.7rem;
				}
			}
		}

		.btn-con{
			margin-top: 2.2rem;

			@media (max-width: $md){
				margin-top: 2.9rem;
			}
		}
	}

	&.black{

		.row{
			padding-top: 15.097rem;
			min-height: 57.2rem;

			@media (max-width: $md){
				padding-top: 11.897rem;
				min-height: 41.5rem;
			}
		}

		.text{

			h2{
				width: 90.7rem;
				width: 110.7rem;

				@media (max-width: $md){
					width: 29rem;
				}
			}
		}
	}

	.row{
		padding-top: 15.097rem;
		padding-bottom: 5.5rem;
		min-height: 51.2rem;
		align-items: center;
		text-align: center;

		@media (min-width: $md + 1){
			padding-top: 9.197rem;
			padding-bottom: 9.197rem;
		}

		@media (max-width: $md){
			padding-top: 9rem;
			padding-bottom: 9rem;
			min-height: 0;
		}

		&:has(p){
			min-height: 53.9rem;
			padding-bottom: 6.6rem;

			@media (max-width: $md){
				min-height: 0rem;
				padding-bottom: 9rem;
			}
		}
	}

	.text{

		h1{
			width: 90rem;
			margin-left: auto;
			margin-right: auto;
		}

		h2{
			width: 101.7rem;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: .36em;
	
			@media (max-width: $md){
				width: 32rem;
				@include font-size(36);
				@include line-height(36,36);
				margin-bottom: .5em;

				&:last-child{
					margin-bottom: 0;
				}
			}
		}

		p{
			width: 80.5rem;
			margin-left: auto;
			margin-right: auto;

			@media (max-width: $md){
				width: 31.7rem;
			}
		}

		.btn{
			padding-left: 2rem;
			padding-right: 2rem;
		}
	}
}

.hero-section-spacer{
	position: static;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	margin-top: 0;
	border-radius: 0;
}

.full-width-video-section{
	
	video{
		width: 100%;
	}
}

.center-text-section{

	&:has(+ .gradient-text-with-image-section){

		.row{

			@media (max-width: $md){
				padding-bottom: 5.7rem;
			}
		}
	}

	.container{
		max-width: 128rem;
	}

	.row{
		padding-top: 11rem;
		padding-bottom: 8rem;
		text-align: center;

		@media (max-width: $md){
			padding-top: 2.5rem;
			padding-bottom: 2.3rem;
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6{
		margin-left: auto;
		margin-right: auto;
		width: 94rem;
	}


	p{
		text-align: left;
		margin-left: auto;
		margin-right: auto;
		width: 72.6rem;

		// @media (max-width: $md){
		// 	text-align: center;
		// }
	}
}

.sectors-archive-section + .clients-slide-section,
.title-with-text-section.black + .clients-slide-section,
.download-form-section + .clients-slide-section,
.detailed-list-section + .clients-slide-section{

	.row{
		padding-bottom: 6.2rem;

		@media (max-width: $md){
			padding-bottom: 3.4rem;
		}
	}
}

.clients-slide-section{

	&.white{

		+ .featured-work-section.white{
			
			.row{
				padding-top: 0;
			}
		}

		.dark-contrast &{

			img{
				filter: brightness(10000000000000000) contrast(999999999999999999999%) grayscale(9999999999999%) hue-rotate(180deg) saturate(30%);
			}
		}
	}

	.row{
		padding-top: 6rem;
		padding-bottom: 10rem;
		text-align: center;

		@media (max-width: $md){
			padding-top: 3rem;
			padding-bottom: 5.3rem;
		}
	}

	.title-con{
		margin-bottom: 5rem;

		@media (max-width: $md){
			margin-bottom: 3.9rem;
		}
	}

	.clients-slide{
		width: calc(100% + 3.2rem + 3.2rem);
		max-width: calc(100% + 3.2rem + 3.2rem);
		margin-left: -3.2rem;
		pointer-events: none;

		@media (max-width: $md){
			width: calc(100% + 1.4rem + 1.4rem);
			max-width: calc(100% + 1.4rem + 1.4rem);
			margin-left: -1.4rem;
		}

		.swiper-wrapper-con{
			overflow: visible!important;
		}

		.swiper-wrapper{
			transition-timing-function: linear;
			transition-duration: 8000ms!important;
		}

		.swiper-slide{
			padding: 0 3.2rem;

			@media (max-width: $md){
				padding: 0 1.4rem;
			}

			.inner{
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;

				img{
					max-width: 16.8rem;
					width: auto!important;
					max-height: 10rem;
		
					@media (max-width: $md){
						max-width: 8rem;
						max-height: 6rem;
					}
				}
			}
		}
	}
}

.awards-section{

	@media (min-width: $md + 1){
		
		.container,
		.col-6,
		.col-12{
			padding-right: 2.1rem;
			padding-left: 2.1rem;
		}

		.row{
			margin-right: -2.1rem;
			margin-left: -2.1rem;
		}
	}

	&.black{

		// .swiper-slide .inner p.large.year, 
		// .col-6 .inner p.large.year{
		// 	color: $black;
		// }
	}

	&:has(.swiper-button-prev.swiper-button-lock, .swiper-button-next.swiper-button-lock ){

		.row{

			@media (max-width: $md){
				padding-bottom: 3.9rem;
			}
		}
	}

	.container{
		max-width: 130.9rem;
	}

	.row{
		padding-top: 5.5rem;
		padding-bottom: 9.9rem;
		text-align: center;

		@media (max-width: $md){
			padding-top: 2.2rem;
			padding-bottom: 9.9rem;
		}
	}

	.title-con{
		margin-bottom: 5.3rem;

		@media (max-width: $md){
			margin-bottom: 2rem;
		}
	}

	.awards-slide-con{

		@media (min-width: $md + 1){
			
			&:not(:has(.swiper-slide:nth-of-type(4))){

				.swiper-wrapper{
					justify-content: center;
				}
			}
		}

		.swiper-wrapper-con{
			overflow: visible!important;
		}

		.swiper-button-next, 
		.swiper-button-prev{

			@media (min-width: $md + 1){
				bottom: -3.4rem;
			}
		}

		@media (min-width: $md + 1){
			
			.swiper-button-next{
				right: 1.8rem;
			}

			.swiper-button-prev{
				right: 7.8rem;
			}
		}

		.awards-slide{
			
			@media (min-width: $md + 1){
				width: calc(100% + 2.1rem + 2.1rem);
				max-width: calc(100% + 2.1rem + 2.1rem);
				margin-left: -2.1rem;
			}
		}

		.swiper-slide{
		
			@media (min-width: $md + 1){
				padding-right: 2.1rem;
				padding-left: 2.1rem;
			}
		}
	}

	
	.swiper-slide,
	.col-6{
		margin-bottom: 4.2rem;

		@media (max-width: $md){
			margin-bottom: .9rem;
		}

		.inner{
			width: 100%;
			border: .2rem solid $white;
			border-radius: 2.5rem;
			padding: 1.5rem 1rem .5rem;

			@media (max-width: $md){
				border-radius: 1.5rem;
				padding: 1.2rem 1rem .5rem;
			}

			.award{
				color: $black;
				background-color: $gold;
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 3.15rem;
				@include font-size(14);
				display: inline-block;
				padding: .6rem 1.4rem;
				border-radius: 4rem;

				@media (max-width: $md){
					@include font-size(10);
					padding: .5rem 1.5rem;
					margin-bottom: 1.15rem;
				}

				&.commended{
					background-color: $silver;
				}

				&.finalist{
					background-color: $bronze;
				}
			}

			.img-con,
			.text{
				display: flex;
				flex-direction: column;
				justify-content: center;
			}

			p.large{
				@include line-height(24,30);
				margin-bottom: 0;

				&.year{
					color: $form;
				}

				@media (max-width: $md){
					@include font-size(16);
					@include line-height(13,20);
				}
			}

			img{
				margin: .8rem auto 0rem;
				max-width: 55%!important;

				@media (max-width: $md){
					margin: .3rem auto 0;
					width: 11rem!important;
				}
			}
		}
	}
}

.work-section{

	.container{
		max-width: 137.6rem;
	}

	.row{
		padding-top: 6rem;
		padding-bottom: 10.3rem;

		@media (max-width: $md){
			padding-top: 2.7rem;
			padding-bottom: 6.1rem;
		}
	}

	.title-con{
		text-align: center;
		margin-bottom: 6rem;

		@media (max-width: $md){
			margin-bottom: 2rem;
		}
	}

	.filler{
		margin-bottom: 6.6rem;
		
		.inner{
			background-color: pink;
			border-radius: 2.5rem;
			min-height: 264rem;
		}
	}

	.under{
		text-align: center;
		margin-top: .5rem;

		@media (max-width: $md){
			margin-top: .1rem;
		}
	}

	.btn-con{
		margin-top: -.9rem;

		@media (max-width: $md){
			margin-top: -.1rem;
		}
	}

		
	.w-item.spotlight{

		.inner{

			.fluid-width-video-wrapper,
			video,
			img{

				@media (min-width: $md + 1){
					aspect-ratio: 1356/763;
				}
			}

			.fluid-width-video-wrapper{

				@media (max-width: $md){
					aspect-ratio: 1356/763;
				}
			}

			h4{

				@media (min-width: $md + 1){
					margin-bottom: .01em;
				}
			}
		}
	}
	
	.w-item{
		margin-bottom: 5.7rem;

		@media (max-width: $md){
			margin-bottom: 2.7rem;

			// &:nth-of-type(1n + 4){
			// 	display: none;
			// }
		}
	}
}

.latest-work-section{

	.row{
		padding-top: 7.4rem;
		padding-bottom: 8.7rem;
	}

	.title-con{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 3.6rem;

		h3{
			margin-bottom: 0;
		}

		.btn{
			padding-left: 2.6rem;
			padding-right: 2.6rem;
		}
	}

	.w-item{

		@media (min-width: $md + 1){
			
			&:nth-child(3n+2):nth-last-child(-n+3),
			&:nth-child(3n+2):nth-last-child(-n+3) ~ .w-item{
				margin-bottom: 0;
			}
		}
	}
}

.sectors-section{

	.row{
		padding-top: 5.3rem;
		padding-bottom: 8.2rem;

		@media (max-width: $md){
			padding-top: 2.4rem;
			padding-bottom: 5.5rem;
		}
	}

	.title-side{

		@media (max-width: $md){
			margin-bottom: 1.1rem;
		}
	}

	.text-side{

		@media (min-width: $md + 1){
			padding-top: .5rem;
			padding-left: 12.6rem;
			padding-right: 4rem;	
		}
	}

	.btn-con{
		margin-top: 8.1rem;
		justify-content: space-between;

		@media (max-width: $md){
			margin-top: 2.7rem;
			justify-content: flex-start;
		}

		.btn{

			&.green{
				
				&:hover,
				&:focus-visible{
					border-color: $green;
					background-color: $green;
				}
			}

			
			&.blue{
				
				&:hover,
				&:focus-visible{
					border-color: $blue;
					background-color: $blue;
				}
			}
			
			&.purple{
				
				&:hover,
				&:focus-visible{
					border-color: $purple;
					background-color: $purple;
				}
			}

			&.pink{
				
				&:hover,
				&:focus-visible{
					border-color: $pink;
					background-color: $pink;
				}
			}

			&.yellow{
				
				&:hover,
				&:focus-visible{
					border-color: $yellow;
					background-color: $yellow;
				}
			}

			&.grey{
				
				&:hover,
				&:focus-visible{
					border-color: $grey-dark;
					background-color: $grey-dark;
				}
			}
		}
	}
}

.featured-articles-section{

	.single-sectors &{

		.row{
			padding-top: 6.6rem;
			padding-bottom: 4.5rem;

			@media (max-width: $md){
				padding-top: 3.9rem;
				padding-bottom: 2.5rem;

				h3{
					letter-spacing: -.02em;
					@include font-size(24);
					@include line-height(24,28);
				}

				h6{
					@include font-size(20);
					@include font-size(16);
					@include line-height(20,22);
				}

				.in-item{
					margin-bottom: 3.1rem;
				}

				.title-side{
					flex: 0 0 100%;
					max-width: 100%;
					margin-bottom: 3.3rem;
				}

				.btn-side{
					flex: 0 0 100%;
					max-width: 100%;
					order: 8;
					margin-top: 1.4rem;

					.btn-con{
						justify-content: center;
					}
					
				}
			}
		}
	}

	.single-blogs &,
	.single-news &{

		.row{
			padding-top: 5.3rem;
			padding-bottom: 11rem;

			@media (max-width: $md){
				padding-bottom: 1.8rem;

				.title-side,
				.btn-side{
					margin-bottom: 3.1rem;

					h3{
						@include font-size(24);
						@include line-height(24,28);
						letter-spacing: -.02em;
					}
				}
			}
		}
	}

	.container{
		max-width: 144.1rem;
	}

	@media (min-width: $md + 1){
		
		.container,
		.col-6,
		.col-8,
		.col-4,
		.col-12{
			padding-right: 4.1rem;
			padding-left: 4.1rem;
		}

		.row{
			margin-right: -4.1rem;
			margin-left: -4.1rem;
		}
	}

	.row{
		padding-top: 9.9rem;
		padding-bottom: 8.5rem;

		@media (max-width: $md){
			padding-top: 4.5rem;
			padding-bottom: 8.5rem;
		}
	}

	.title-side{
		margin-bottom: 5.4rem;

		@media (max-width: $md){
			margin-bottom: 2.1rem;
			align-self: center;

			.single-insight &,
			.single-news &{

				h3{
					letter-spacing: -.02em;
					@include font-size(24);
					@include line-height(24,28);
				}
			}
		}
	}

	.btn-side{

		@media (max-width: $md){
			margin-bottom: 2.1rem;
			align-self: center;
		}
	}

	.btn-con{
		margin-top: 0;

		@media (min-width: $md + 1){
			
			.btn{
				padding-left: 2.9rem;
				padding-right: 2.9rem;
			}
		}

		.btn{

			@media (max-width: $md){
				padding: .87rem 1.2rem;
				min-width: 11.773rem;
			}
		}
	}

	.i-item{
		margin-bottom: 4.8rem;

		.inner{
			background-color: pink;
			border-radius: 2.5rem;
			min-height: 46.8rem;
		}
	}

	.k-item{

		@media (max-width: $md){
			margin-bottom: 4rem;

			.inner{

				img{
					aspect-ratio: 353/297;
				}
			}
		}
	}
}

.from-the-knowledge-bank-section{

	.container{
		max-width: 144.1rem;
	}

	@media (min-width: $md + 1){
		
		.container,
		.col-6,
		.col-8,
		.col-4,
		.col-12{
			padding-right: 4.1rem;
			padding-left: 4.1rem;
		}

		.row{
			margin-right: -4.1rem;
			margin-left: -4.1rem;
		}
	}

	.row{
		padding-top: 5.9rem;
		padding-bottom: 6rem;

		@media (max-width: $md){
			padding-top: 3.9rem;
			padding-bottom: 8rem;
		}
	}

	.title-side{
		margin-bottom: 3.2rem;

		@media (max-width: $md){
			margin-bottom: 3.3rem;
			align-self: center;
			flex: 0 0 100%;
			max-width: 100%;

			h3{
				letter-spacing: -.02em;
				@include font-size(24);
				@include line-height(24,28);
			}
		}
	}

	.btn-side{

		@media (max-width: $md){
			align-self: center;
			order: 8;
			margin-top: 1.4rem;
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

	.btn-con{
		margin-top: 0;

		@media (min-width: $md + 1){
			padding-top: .5rem;
		}

		@media (max-width: $md){
			justify-content: flex-start;
		}
	}

	.k-item{

		@media (max-width: $md){
			margin-bottom: 4rem;

			.inner{

				img{
					aspect-ratio: 353/297;
				}
			}
		}
	}
}

.sign-up-bottom-section{

	.row{
		padding-top: 2.6rem;
		padding-bottom: 2.4rem;

		@media (max-width: $md){
			padding-top: 2.6rem;
			padding-bottom: 2.8rem;
		}
	}

	.title-side{
		margin-bottom: 1.1rem;

		@media (min-width: $md + 1){
			padding-top: 1.9rem;
		}

		@media (max-width: $md){
			text-align: center;

			h3{
				@include font-size(24);
				@include line-height(24,28);
				letter-spacing: -.02em;
			}
		}
	}

	.form-side{

		small{
			margin-top: 1.3rem;
			display: block;
			padding-left: 1.9rem;
			padding-right: 5rem;

			@media (max-width: $md){
				margin-top: 1.7rem;
				padding-left: 0rem;
				padding-right: 0rem;
			}
		}

		small p{
			@include font-size(14);
			@include line-height(14,18);

			@media (max-width: $md){
				@include font-size(9.97);
			}
		}

		@media (min-width: $md + 1){
			padding-top: .6rem;
			padding-left: 10.6rem;
		}

		.gform_confirmation_message{
			display: block;
			padding: 1.367rem 1.8rem;
			padding-right: 6rem;
			font-size: 2rem;
			line-height: 1;
			font-weight: 400;
			color: $white;
			width: 100%;
			max-width: 100%;
			margin-bottom: 0;
			border: 0.2rem solid $black;
			background-color: $black;
			border-radius: 2.5rem;
			position: relative;

			@media (max-width: $md){
				@include font-size(16);
				padding: 1.248rem 1.7rem;
			}

			&:after{
				content: '';
				position: absolute;
				top: -.15rem;
				right: -.2rem;
				width: 5rem;
				aspect-ratio: 1/1;
				border-radius: 5rem;
				background-color: $white;
				background-image: url(../images/page-prev.svg);
				background-repeat: no-repeat;
				background-position: 50% 50%;
				background-size: 3.2rem auto;

				@media (max-width: $md){
					width: 4.5rem;
					background-size: 3rem auto;
					display: none;
				}
			}
			
		}

		.gform_wrapper{
			display: block;

			.gform_heading{
				display: none;
			}
		}

		::-webkit-input-placeholder{
			color: $black;
			font-weight: 500;
		}
		 
		:-moz-placeholder{ /* Firefox 18- */
			color: $black;
			font-weight: 500;
		}
		
		::-moz-placeholder{  /* Firefox 19+ */
			color: $black;
			font-weight: 500;
		}
		
		:-ms-input-placeholder{
			color: $black;
			font-weight: 500;
		}

		input{
			font-weight: 500;
			padding-right: 6.7rem; 
		}

		.gfield{
			margin-bottom: 0;
		}

		.gform_footer{
			margin-top: 0;
			position: absolute;
			top: .1rem;
			right: 0;

			[type="submit"]{
				width: 5rem;
				height: 4.95rem;
				font-size: 0!important;
				padding: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: $black;
				border-color: $black;

				@media (max-width: $md){
					width: 4.5rem;
					height: 4.4rem;
				}

				&:hover,
				&:focus-visible{
					background-color: $yellow;

					svg{

						*{
							fill: $black;
						}
					}
				}

				svg{
					aspect-ratio: 33/14.728;
					width: 3.3rem;

					@media (max-width: $md){
						width: 3rem;
					}

					*{
						fill: $yellow;
					}
				}
			}
		}
	}

	.under-con{

		@media (max-width: $md){
			margin-top: 1.5rem;
		}

		p,
		li{
			@include font-size(14);
			@include line-height(14,18);

			@media (max-width: $md){
				@include font-size(9.97);
			}
		}
	}
}

.full-width-image-section{
	aspect-ratio: 1440/707;

	@media (max-width: $md){
		aspect-ratio: 1498 / 707;
	}
}

.title-with-bullet-points-section + .title-with-text-section{

	.row{
		padding-top: 5rem;
		padding-bottom: 8.5rem;

		@media (max-width: $md){
			padding-top: 2.5rem;
			padding-bottom: 5.5rem;

			h3{
				@include font-size(20);
				@include line-height(20,22);
			}
		}
	}

	.text-side{

		@media (min-width: $md + 1){
			padding-top: .6rem;
			padding-right: 9rem;
		}
	}

	p{

		&:not(.large){

			+ p.large{
				margin-top: 1.75em;

				@media (max-width: $md){
					margin-top: 1.05em;
				}
			}
		}

		&.large{
			margin-bottom: .3em;

			@media (max-width: $md){
				margin-bottom: 0em;
				@include font-size(18);
				@include line-height(18,23.4);
			}
		}
	}
}

.title-with-text-section{

	@media (max-width: $md){
		
		&:has(+ .sign-up-bottom-section){

			.btn-con{
				margin-top: -.3rem;
			}

			.row{
				padding-bottom: 7.1rem;
			}
		}
	}

	&:has(+ .title-with-bullet-points-section){

		@media (max-width: $md){
			
			.row{
				padding-bottom: 4.8rem;
			}
		}
	}

	.single-sectors &{

		&:has(+ .featured-work-section){

			.row{
				padding-bottom: 3.1rem;
			}
		}

		&.black{

			.row{
				padding-bottom: 10.1rem;

				@media (max-width: $md){
					padding-top: 3.6rem;
					padding-bottom: 8.8rem;
				}
			}

			.text-side{

				@media (min-width: $md + 1){
					padding-right: 12rem;
				}
			}
		}

		.row{
			padding-top: 4.9rem;

			@media (max-width: $md){
				padding-top: 2.7rem;
			}
		}

		.title-side{

			@media (min-width: $md + 1){
				padding-right: 14rem;
			}
		}

		.text-side{

			@media (min-width: $md + 1){
				padding-top: .7rem;
				padding-right: 15rem;
			}
		}
	}

	.single-services &{

		.title-side{

			@media (min-width: $md + 1){
				padding-right: 6rem;
			}
		}
	}

	&:has(.services){

		.text-side{

			@media (min-width: $md + 1){
				padding-right: 12.4rem;
				padding-top: 2.4rem;
			}
		}

		.title-side{

			&:nth-of-type(3){
				padding-top: 9.5rem;

				@media (max-width: $md){
					padding-top: 2rem;

					h4{

						@media (max-width: $md){
							@include font-size(20);
							@include line-height(20,22);
						}
					}
					
				}
			}
		}

		.text-side{

			&:nth-of-type(4){
				padding-top: 9.5rem;

				@media (max-width: $md){
					padding-top: 0;
				}

				@media (min-width: $md + 1){
					padding-right: 1rem;
				}
			}
		}
	}

	&.black{

		.single-services &{

			.row{
				padding-top: 2.7rem;
				padding-bottom: 9.3rem;

				@media (max-width: $md){
					padding-bottom: 5.7rem;
				}
				
			}

			.title-side{

				@media (min-width: $md + 1){
					padding-right: 11rem;
				}
			}

			.text-side{

				@media (min-width: $md + 1){
					padding-right: 13rem;
				}
			}
		}

		.row{
			padding-top: 4.7rem;
			padding-bottom: 7.8rem;

			@media (max-width: $md){
				padding-bottom: 4.8rem;
			}
		}

		.text-side{

			@media (min-width: $md + 1){
				padding-top: 1.8rem;
				padding-right: 16rem;
			}
		}

		.award-item{

			.inner{
				border: .2rem solid $white;
			}
		}
	}

	.row{
		padding-top: 6.9rem;
		padding-bottom: 9.5rem;

		@media (max-width: $md){
			padding-top: 2.4rem;
			padding-bottom: 4.5rem;

			h3{
				letter-spacing: -.02em;
				@include font-size(24);
				@include line-height(24,28);

				br{
					display: none;
				}
			}
		}
		
		&:has(.award-item){
			padding-top: 9.8rem;
			padding-bottom: 12.1rem;

			@media (max-width: $md){
				padding-top: 3.5rem;
				padding-bottom: 1.5rem;

				h4{
					letter-spacing: -.02em;
					margin-bottom: .22em;
					@include font-size(24);
					@include line-height(24,28);
				}
			}

			.title-side{

				@media (min-width: $md + 1){
					padding-right: 11rem;
				}
			}

			.text-side{

				@media (min-width: $md + 1){
					padding-left: 1.2rem;
					padding-right: 10rem;
				}
			}
		}
	}

	.title-side{
		
		@media (max-width: $md){
			margin-bottom: 1.6rem;
		}
	}

	.text-side{

		@media (min-width: $md + 1){
			padding-top: .2rem;
			padding-right: 14rem;
		}
	}

	.services{
		column-gap: 2rem;
		column-count: 2;
		
		@media (max-width: $md){
			margin-top: 0rem;
		}

		p.large{
			width: 100%;
			margin-bottom: .5em;

			@media (max-width: $md){
				@include font-size(20);
				@include line-height(20,22);
			}
		}

		.cat{
			@include font-size(24);
			padding-left: 1.6rem;
			position: relative;
			break-inside: avoid;
			display: block;
			margin-bottom: .68em;

			@media (max-width: $md){
				@include font-size(13);
				margin-bottom: 1.08em;
			}

			&:before{
				content: '';
				position: absolute;
				top: 50%;
				left: 0;
				height: .08em;
				width: .333em;
				background-color: currentColor;
				transform: translateY(-50%);
			}
		}

		// .cat{
		// 	border: .1rem solid $black;
		// 	@include font-size(14);
		// 	padding: .45rem .7rem;
		// 	border-radius: 1.45rem;
		// 	margin-bottom: 1rem;

		// 	@media (max-width: $md){
		// 		@include font-size(13);
		// 		padding: .4rem .7rem;
		// 		margin-bottom: .9rem;
		// 	}

		// 	&:has(+ .cat){
		// 		margin-right: 1rem;
		// 	}
		// }
		
	}

	.download-item{
		margin-top: 4.8rem;
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		@media (max-width: $md){
			margin-top: 2.8rem;
		}

		.image-con{
			width: 38.8%;

			@media (max-width: $md){
				width: 41%;
			}

			img{
				width: 100%;
			}
		}

		.text-con{
			width: calc(61.2% - 1.6rem);
			margin-left: 1.6rem;

			@media (max-width: $md){
				width: calc(59% - 3.7rem);
				margin-left: 3.7rem;
			}
		}

		.btn-con{
			margin-top: 1.8rem;
		}
	}

	.btn-con{
		margin-top: 3.3rem;

		@media (max-width: $md){
			margin-top: -0.4rem;
			margin-bottom: 3.5rem;
			margin-top: 2.4rem;
			margin-bottom: 0.2rem;
		}
	}

	.award-item{
		width: 52.4rem;
		margin-top: 3.6rem;

		@media (max-width: $md){
			margin-top: 1.6rem;
		}
		
		.inner{
			background-color: $black;
			width: 100%;
			border-radius: 2.5rem;
			padding: 2.6rem 3rem 2.8rem;
			display: flex;
			flex-wrap: wrap;

			.black &{
				border: .2rem solid $white;
			}

			@media (max-width: $md){
				border-radius: 1.5rem;
				text-align: center;
				padding: 2.6rem 3rem 2rem;
			}

			.award{
				color: $black;
				background-color: $gold;
				margin-bottom: .75rem;
				@include font-size(14);
				display: inline-block;
				padding: .6rem 1.4rem;
				border-radius: 4rem;

				@media (max-width: $md){
					order: 2;
					margin: 0 auto .7rem;
				}

				&.commended{
					background-color: $silver;
				}

				&.finalist{
					background-color: $bronze;
				}
			}

			.img-con,
			.text{
				display: flex;
				flex-direction: column;
				justify-content: center;
			}

			.text{
				width: 61.4%;

				@media (max-width: $md){
					order: 4;
					width: 100%;
				}
			}

			.img-con{
				width: 38.6%;

				@media (max-width: $md){
					order: 1;
					width: 100%;
				}
			}

			.spacer{
				width: 100%;

				@media (max-width: $md){
					order: 3;
				}
			}

			p.large{
				@include line-height(24,30);
				margin-bottom: 0;
				color: $white;

				@media (max-width: $md){
					@include font-size(18);
					@include line-height(18,23.4);
				}

				&.year{
					color: $form;
				}
			}


			img{
				margin: -3.2rem auto 0;
				
				@media (max-width: $md){
					margin: -1.7rem auto 1.1rem;
					width: 25.5rem !important;
				}
			}
		}
	}
}

.title-with-text-section + .double-images-section{

	// .fluid-width-video-wrapper,
	// video,
	// img{
	// 	aspect-ratio: 670/520;
	// 	object-fit: cover;
	// 	object-position: 50% 50%;
	// }

	// .fluid-width-video-wrapper{

	// 	@media (min-width: $md + 1){
	// 		padding-top: 0;
	// 		height: 100%;
	// 	}
	// }
}

.text-with-image-section{

	+ .single-image-section,
	+ .double-images-section,
	+ .triple-images-section{

		.row{
			padding-top: 5.3rem;

			@media (max-width: $md){
				padding-top: 2.8rem;
			}
		}
	}
}

.single-image-section,
.double-images-section,
.triple-images-section{

	&:has(+ .single-image-section),
	&:has(+ .double-images-section),
	&:has(+ .triple-images-section){

		.row{
			padding-bottom: 2rem;
		}
	}

	&:has(+ .title-with-text-section){

		& + .title-with-text-section{

			.row{
				padding-top: 3.2rem;
				padding-bottom: 11rem;

				@media (max-width: $md){
					padding-top: 2.2rem;
					padding-bottom: 5.7rem;
				}
			}
		}

		.row{
			padding-bottom: 5rem;

			@media (max-width: $md){
				padding-bottom: 1.5rem;
			}
		}
	}

	& + .single-image-section,
	& + .double-images-section,
	& + .triple-images-section{
		margin-top: -2rem;
	}

	.row{
		padding-top: 2rem;
		padding-bottom: 12.5rem;

		@media (max-width: $md){
			padding-bottom: 6.8rem;
		}

		.col-12{

			@media (max-width: $md){
				
				&:has(+ .col-12){
					margin-bottom: 2.2rem;
				}
			}
		}
	}

	.fluid-width-video-wrapper,
	video,
	img{
		border-radius: 2.5rem;
		width: 100%;
		overflow: hidden;

		@media (max-width: $md){
			border-radius: 1.5rem;


		}
	}
}

.hero-section:has(+ .hero-section-spacer + .detailed-list-section){

	& + .hero-section-spacer .row,
	.row{
		padding-top: 18.497rem;

		@media (max-width: $md){
			padding-top: 12.397rem;
		}

		.text{

			h2{
				width: 118rem;
				margin-bottom: .5em;

				@media (max-width: $md){
					margin-bottom: .9em;
				}
			}

			p{
				width: 88.5rem;

				@media (max-width: $md){
					width: 32.5rem;
				}
			}
		}

	}
}

.detailed-list-section{

	&:has(+ .clients-slide-section){

		.row{
			padding-bottom: 2.7rem;
		}

		@media (min-width: $md + 1){
			
			dl{

				dt,
				dd{
	
					&:last-of-type{
						border-bottom: 1px solid #d9d9d9;
					}
				}
			}
		}
	}

	&.white{

		.row{
			padding-top: 17.4rem;

			@media (max-width: $md){
				padding-top: 7.8rem;

				h4{
					@include font-size(18);
					@include line-height(18,23.4);
				}
			}
		}

		p + ul{

			@media (max-width: $md){
				margin-top: 1rem;
			}
		}

		p.large{
			margin-bottom: 1.3em;
		}

		dl{

			dt{

				~ dt{
					padding-top: 3.3rem;
				}
			}

			dd{

				@media (max-width: $md){
					padding-bottom: 3.3rem;
				}

				*:last-child{
					margin-bottom: 0;
				}

				@media (min-width: $md + 1){
					padding-right: 0rem;
					padding-bottom: 3.7rem;
				}

				~ dd{
					padding-top: 3.3rem;

					@media (max-width: $md){
						padding-top: .2rem;
					}
				}
			}
		}
	}

	.container{
		max-width: 137.4rem;
	}

	.row{
		padding-top: 4.9rem;
		padding-bottom: 5.2rem;

		@media (max-width: $md){
			padding-top: 3.2rem;
			padding-bottom: 4.6rem;
		}
	}

	dl{
		width: 100%;
		display: flex;
		flex-wrap: wrap;

		dt{
			width: 50%;
			padding-right: 1rem;
			border-bottom: 1px solid #d9d9d9;

			@media (max-width: $md){
				width: 100%;
				border-bottom: none;
				margin-bottom: 1rem;
				
				h4{
					@include font-size(20);
					@include line-height(20,22);
				}
			}

			&:last-of-type{
				border-bottom: none;
			}

			~ dt{
				padding-top: 3.7rem;

				@media (max-width: $md){
					padding-top: 2.2rem;
				}
			}

		
		}

		dd{
			width: 50%;
			margin: 0;
			border-bottom: 1px solid #d9d9d9;

			@media (max-width: $md){
				width: 100%;
				padding-bottom: 2.2rem;
			}

			&:last-of-type{
				border-bottom: none;
			}

			~ dd{
				padding-top: 3.7rem;

				@media (max-width: $md){
					padding-top: 0rem;
				}
			}

			@media (min-width: $md + 1){
				padding-top: .4em;
				padding-left: 1rem;
				padding-right: 4rem;
				padding-bottom: 3.4rem;
			}
		}
	}
}


.title-with-form-section{

	&:nth-of-type(1){

		.row{
			padding-top: 19rem;

			@media (max-width: $md){
				padding-top: 10.6rem;
			}
		}
	}

	.container{
		max-width: 101.5rem;
	}

	.row{
		padding-top: 7.1rem;
		padding-bottom: 15.6rem;

		@media (max-width: $md){
			padding-bottom: 6.7rem;
		}
	}

	.title-con{
		text-align: center;
		margin-bottom: 6.6rem;

		@media (max-width: $md){
			
			h2{
				@include font-size(36);
				@include line-height(36,36);
				margin-left: auto;
				margin-right: auto;
				width: 30rem;
			}
		}
	}

	.form-con{

		@media (min-width: $md + 1){
			padding-left: 5.8rem;
			padding-right: 5.8rem;
		}

		.gform_heading.gform_heading.gform_heading.gform_heading{

			p{

				@media (min-width: $md + 1){
					column-count: 2;
					break-inside: avoid;
					width: 100%;
				}
			}
		}
	}

	.gform_confirmation_message{
		text-align: center;
	}

	.gfield{

		&.gfield--type-textarea{

			.ginput_container_textarea{
				border-radius: 2.5rem;
				overflow: hidden;
				position: relative;

				&::before{
					content: '';
					position: absolute;
					width: 2.0rem;
					height: 2.0rem;
					right: .5rem;
					bottom: .5rem;
					border-right: .2rem solid currentColor;
					border-bottom: .2rem solid currentColor;
					border-radius: 0 0 100% 0;
					pointer-events: none;
				}

				&::after{
					content: '';
					position: absolute;
					width: 1.5rem;
					height: 1.5rem;
					right: 1rem;
					bottom: 1rem;
					border-right: .2rem solid currentColor;
					border-bottom: .2rem solid currentColor;
					border-radius: 0 0 100% 0;
					pointer-events: none;
				}
			}
		}
	}
}

.contact-us-section{

	.row{
		padding-top: 4.2rem;
		padding-bottom: 10.5rem;

		@media (max-width: $md){
			padding-top: 2.9rem;
			padding-bottom: 7.8rem;

			h3{
				letter-spacing: -.02em;
				@include font-size(24);
				@include line-height(24,28);
				margin-bottom: .5em;
			}
		}
	}

	.email-side,
	.tel-side,
	.title-side{

		@media (min-width: $md + 1){
			flex: 0 0 41.6%;
			max-width: 41.6%;
		}
	}

	.new-side,
	.address-side,
	.text-side{

		@media (min-width: $md + 1){
			flex: 0 0 58.4%;
			max-width: 58.4%;
		}
	}

	.email-side,
	.tel-side,
	.new-side,
	.address-side{

		p{
			margin-bottom: .4em;

			@media (max-width: $md){
				margin-bottom: .2em;
			}

			&.large{

				@media (max-width: $md){
					@include font-size(18);
					@include line-height(18,23.4);
				}
			}
		}

		a,
		address{
			@include font-size(36);
			@include line-height(36,47);

			@media (max-width: $md){
				@include font-size(20);
				@include line-height(20,22);
			}
		}

		address{

			@media (min-width: $md + 1){
				padding-right: 28rem;
			}
		}

		a{
			text-decoration: none;

			&:hover,
			&:focus-visible{
				text-decoration: underline;
			}
		}
	}

	.text-side{

		@media (min-width: $md + 1){
			padding-top: 1.5rem;
			padding-right: 28rem;
		}
	}

	.tel-side,
	.address-side{
		padding-top: 6.9rem;
		padding-bottom: 2.8rem;
		position: relative;
		margin-bottom: 2.4rem;

		@media (max-width: $md){
			padding-top: 2.8rem;
		}

		&:after{
			content: '';
			position: absolute;
			bottom: 0;
			height: 1px;
			background-color: $black;
			left: 0;
			right: 0;
		}
	}

	.tel-side{

		@media (max-width: $md){
			padding-bottom: 0;
			margin-bottom: 0;
		}

		&:after{
			left: 1rem;

			@media (max-width: $md){
				display: none;
			}
		}
	}

	.address-side{

		@media (max-width: $md){
			padding-bottom: 4.3rem;
			margin-bottom: 3.3rem;
		}

		&:after{
			right: 1rem;

			@media (max-width: $md){
				right: .5rem;
				left: .5rem;
			}
		}
	}

	.email-side{

		@media (max-width: $md){
			margin-bottom: 3rem;
		}
	}
}

.stay-in-the-know-section{

	&:nth-of-type(1){
		margin-top: 16.1rem;
		border-radius: 2.5rem 2.5rem 0 0;

		@media (max-width: $md){
			margin-top: 11.1rem;
			border-radius: 1.5rem 1.5rem 0 0;
		}
	}

	.container{
		max-width: 92rem;
	}

	.row{
		padding-top: 4.3rem;
		padding-bottom: 9.5rem;

		@media (max-width: $md){
			padding-top: 3.3rem;
			padding-bottom: 5.5rem;
		}
	}

	.text-con{
		text-align: center;
		margin-bottom: 2.6rem;

		.text{
			margin-left: auto;
			margin-right: auto;
			width: 51rem;
		}

		h2{
			margin-bottom: .2em;

			@media (max-width: $md){
				@include font-size(36);
				@include line-height(36,36);
				margin-bottom: .4em;
			}
		}
	}

	.form-con{

		.gform_wrapper{
			display: flex;
			flex-direction: column;

			.gform_heading{
				//display: none;
				margin-top: -.8rem;
				padding-left: 2.2rem;

				p{
					width: 75rem;
				}
			}
		}

		::-webkit-input-placeholder{
			color: $black;
			font-weight: 500;
		}
		 
		:-moz-placeholder{ /* Firefox 18- */
			color: $black;
			font-weight: 500;
		}
		
		::-moz-placeholder{  /* Firefox 19+ */
			color: $black;
			font-weight: 500;
		}
		
		:-ms-input-placeholder{
			color: $black;
			font-weight: 500;
		}

		input{
			font-weight: 500;
			padding-right: 6.7rem; 
		}

		.gfield{
			margin-bottom: 1.5rem;

			&.gfield--type-checkbox{
				padding-left: 2.2rem;

				@media (max-width: $md){
					padding-left: 0;
					padding-top: 2.6rem;
				}
			}
		}

		.gform_footer{
			margin-top: 0;
			position: absolute;
			top: .1rem;
			right: 0;

			[type="submit"]{
				width: 5rem;
				height: 5rem;
				font-size: 0!important;
				padding: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: $black;
				border-color: $black;

				@media (max-width: $md){
					width: 4.5rem;
					height: 4.5rem;
				}

				&:hover,
				&:focus-visible{
					background-color: $yellow;

					svg{

						*{
							fill: $black;
						}
					}
				}

				svg{
					aspect-ratio: 33/14.728;
					width: 3.3rem;

					@media (max-width: $md){
						width: 3rem;
					}

					*{
						fill: $yellow;
					}
				}
			}
		}
	}
}

.filter-con{
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	> span{
		@include font-size(14);
		font-weight: 500;
		margin-right: 1.5rem;
		margin-bottom: 1rem;

		@media (max-width: $md){
			margin-bottom: 1rem;
			margin-right: 0;
			width: 100%;
			text-align: center;
			display: block;
		}
	}

	.facetwp-type-radio{
		display: flex;
		flex-wrap: wrap;

		@media (max-width: $md){
			justify-content: center;
		}

		.facetwp-radio{
			padding-left: 0;
			background-image: none;
			margin: 0 .5rem 1rem;
			padding: .4rem 1rem;
			background-color: $transparent;
			border: .1rem solid $black;
			@include font-size(14);
			border-radius: 1.4rem;
			transition: $time;

			@media (max-width: $md){
				@include font-size(13);
				padding: .35rem 1rem;
			}

			&:hover,
			&:focus-visible{
				background-color: $blue;
			}

			&.checked{
				background-color: $black;
				border-color: $black;
				color: $white;
			}

			.facetwp-display-value{
				padding-right: 0;
			}

			.facetwp-counter{
				display: none;
			}
		}
	}
}


.clients-archive-section{

	.hero-menu{
		position: absolute;
		top: -11.1rem;
		top: 10.1rem;
		left: 0rem;
		@include font-size(14);
		display: flex;
		align-items: center;

		&.parent-menu{

			.btn{

				&:nth-of-type(1){

					&.active,
					&:hover,
					&:focus-visible{
						background-color: $blue;
						border-color: $blue;
						color: $black!important;
					}
				}

				&:nth-of-type(2){

					&.active,
					&:hover,
					&:focus-visible{
						background-color: $purple;
						border-color: $purple;
						color: $black!important;
					}
				}

				&:nth-of-type(3){

					&.active,
					&:hover,
					&:focus-visible{
						background-color: $yellow;
						border-color: $yellow;
						color: $black!important;
					}
				}

				&:nth-of-type(4){

					&.active,
					&:hover,
					&:focus-visible{
						background-color: $green;
						border-color: $green;
						color: $black!important;
					}
				}

				&:nth-of-type(5){

					&.active,
					&:hover,
					&:focus-visible{
						background-color: $pink;
						border-color: $pink;
						color: $black!important;
					}
				}

				// &:nth-of-type(6),
				// &:nth-of-type(6) + a{
				// 	display: none;
				// }
			}
		}

		@media (max-width: $md){
			display: none;
		}

		.back,
		span{
			font-weight: 500;
			padding: 1.4rem 1.7rem 1.4rem 0;
			border-right: .1rem solid $black;
			order: 1;
			text-decoration: none;
		}

		.back{

			&:hover,
			&:focus-visible{
				color: $blue;
			}
		}

		.btn.small{
			font-size: 1.4rem !important;
			margin-left: 1.5rem;
			padding: .4rem .7rem;
			order: 1;

			&.green{

				&.active,
				&:hover,
				&:focus-visible{
					background-color: $green;
					border-color: $green;
					color: $black!important;
				}
			}
		
			&.yellow{
		
				&.active,
				&:hover,
				&:focus-visible{
					background-color: $yellow;
					border-color: $yellow;
					color: $black!important;
				}
			}
		
			&.blue{
		
				&.active,
				&:hover,
				&:focus-visible{
					background-color: $blue;
					border-color: $blue;
					color: $black!important;
				}
			}

			&.grey{
		
				&.active,
				&:hover,
				&:focus-visible{
					background-color: $grey-dark;
					border-color: $grey-dark;
					color: $black!important;
				}
			}
		
			&.purple{
		
				&.active,
				&:hover,
				&:focus-visible{
					background-color: $purple;
					border-color: $purple;
					color: $black!important;
				}
			}

			&.pink{
		
				&.active,
				&:hover,
				&:focus-visible{
					background-color: $pink;
					border-color: $pink;
					color: $black!important;
				}
			}

			&.active{
				pointer-events: none;
				order: 1;
			}
		}
	}
	
	.col-12{
		position: static;
	}

	.container{
		max-width: 136rem;
	}

	.row{
		padding-top: 23.1rem;
		padding-bottom: 5.4rem;

		@media (max-width: $md){
			padding-top: 9.9rem;
		}
	}

	.text{
		text-align: center;

		h2{
			width: 118rem;
			margin-left: auto;
			margin-right: auto;

			@media (max-width: $md){
				@include font-size(36);
				@include line-height(36,36);
			}
		}
	}

	.filter-con{
		margin-top: 6.9rem;
		margin-bottom: 4.7rem;
		justify-content: center;

		@media (max-width: $md){
			margin-top: 3.1rem;
			margin-bottom: .3rem;
		}
	}

	.facetwp-template{
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}
}

.c-item{
	display: flex;
	border-top: 1px solid $grey;
	position: relative;

	// &:last-of-type{

	// 	&:after{
	// 		display: none;
	// 	}
	// }

	@media (min-width: $md + 1){
		
		&:nth-of-type(6n + 6){

			&:after{
				display: none;
			}
		}

		&:nth-of-type(1),
		&:nth-of-type(2),
		&:nth-of-type(3),
		&:nth-of-type(4),
		&:nth-of-type(5),
		&:nth-of-type(6){
			border-top: none;
		}

		// &:nth-child(6n+1):nth-last-child(-n+6),
		// &:nth-child(6n+1):nth-last-child(-n+6) ~ .c-item{
		// 	border-bottom: none;
		// }
	}

	@media (max-width: $md){

		&:nth-of-type(3n + 3){

			&:after{
				display: none;
			}
		}

		// &:nth-child(3n+1):nth-last-child(-n+3),
		// &:nth-child(3n+1):nth-last-child(-n+3) ~ .c-item{
		// 	border-bottom: none;
		// }

		&:nth-of-type(1),
		&:nth-of-type(2),
		&:nth-of-type(3){
			border-top: none;
		}
	}

	&:after{
		content: '';
		position: absolute;
		top: 1.8rem;
		bottom: 1.8rem;
		right: 0;
		width: 1px;
		background-color: $grey;

		@media (max-width: $md){
			top: .9rem;
			bottom: .9rem;
		}
	}

	.inner{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		min-height: 18rem;

		@media (max-width: $md){
			min-height: 11rem;
		}

		img{
			max-width: 16.8rem;
			width: auto;
			max-height: 10rem;

			@media (max-width: $md){
				max-width: 8rem;
				max-height: 6rem;
			}
		}
	}
}

.team-archive-section{

	.col-12{
		position: static;
	}

	.hero-menu{
		position: absolute;
		top: -11.1rem;
		top: 10.1rem;
		left: 0rem;
		@include font-size(14);
		display: flex;
		align-items: center;

		&.parent-menu{

			.btn{

				&:nth-of-type(1){

					&.active,
					&:hover,
					&:focus-visible{
						background-color: $blue;
						border-color: $blue;
						color: $black!important;
					}
				}

				&:nth-of-type(2){

					&.active,
					&:hover,
					&:focus-visible{
						background-color: $purple;
						border-color: $purple;
						color: $black!important;
					}
				}

				&:nth-of-type(3){

					&.active,
					&:hover,
					&:focus-visible{
						background-color: $yellow;
						border-color: $yellow;
						color: $black!important;
					}
				}

				&:nth-of-type(4){

					&.active,
					&:hover,
					&:focus-visible{
						background-color: $green;
						border-color: $green;
						color: $black!important;
					}
				}

				&:nth-of-type(5){

					&.active,
					&:hover,
					&:focus-visible{
						background-color: $pink;
						border-color: $pink;
						color: $black!important;
					}
				}

				// &:nth-of-type(6),
				// &:nth-of-type(6) + a{
				// 	display: none;
				// }
			}
		}

		@media (max-width: $md){
			display: none;
		}

		.back,
		span{
			font-weight: 500;
			padding: 1.4rem 1.7rem 1.4rem 0;
			border-right: .1rem solid $black;
			order: 1;
			text-decoration: none;
		}

		.back{

			&:hover,
			&:focus-visible{
				color: $blue;
			}
		}

		.btn.small{
			font-size: 1.4rem !important;
			margin-left: 1.5rem;
			padding: .4rem .7rem;
			order: 1;

			&.green{

				&.active,
				&:hover,
				&:focus-visible{
					background-color: $green;
					border-color: $green;
					color: $black!important;
				}
			}
		
			&.yellow{
		
				&.active,
				&:hover,
				&:focus-visible{
					background-color: $yellow;
					border-color: $yellow;
					color: $black!important;
				}
			}
		
			&.blue{
		
				&.active,
				&:hover,
				&:focus-visible{
					background-color: $blue;
					border-color: $blue;
					color: $black!important;
				}
			}

			&.grey{
		
				&.active,
				&:hover,
				&:focus-visible{
					background-color: $grey-dark;
					border-color: $grey-dark;
					color: $black!important;
				}
			}
		
			&.purple{
		
				&.active,
				&:hover,
				&:focus-visible{
					background-color: $purple;
					border-color: $purple;
					color: $black!important;
				}
			}

			&.pink{
		
				&.active,
				&:hover,
				&:focus-visible{
					background-color: $pink;
					border-color: $pink;
					color: $black!important;
				}
			}

			&.active{
				pointer-events: none;
				order: 1;
			}
		}
	}

	// @media (min-width: $md + 1){
		
	// 	.container,
	// 	.col-6,
	// 	.col-12{
	// 		padding-right: 4.5rem;
	// 		padding-left: 4.5rem;
	// 	}

	// 	.row{
	// 		margin-right: -4.5rem;
	// 		margin-left: -4.5rem;
	// 	}
	// }

	@media (max-width: $md){
		
		.col-6{
			padding-right: 0rem;
			padding-left: 0rem;
		}
	}

	.container{
		max-width: 136rem;
	}

	.row{
		padding-top: 23.5rem;
		padding-bottom: 10.4rem;

		@media (max-width: $md){
			padding-top: 11.7rem;
			padding-bottom: 4.2rem;
		}
	}

	.text{
		text-align: center;
		margin-bottom: 6.1rem;

		@media (max-width: $md){
			padding-left: 0;
			padding-right: 0;
			margin-left: -.5rem;
			width: calc(100% + 1rem);
			max-width: calc(100% + 1rem);
		}

		h2{
			width: 100rem;
			margin-left: auto;
			margin-right: auto;

			@media (max-width: $md){
				@include font-size(36);
				@include line-height(36,36);
			}
		}

		h3{
			margin-left: auto;
			margin-right: auto;
			width: 100rem;
			margin-bottom: .56em;

			@media (max-width: $md){
				@include font-size(36);
				@include line-height(36,36);
				letter-spacing: -.02em;
			}
		}
	
		h4{
			margin-left: auto;
			margin-right: auto;
			width: 100rem;
		}
	}
}

.t-item{
	margin-bottom: 4.4rem;
	transition: $time;

	&:nth-of-type(odd){

		.inner{

			.bio{
	
				@media (max-width: $md){
					left: auto;
					right: 0rem;
				}
			}
		}
	}

	&.hiring{

		.inner{

			img{
				margin-top: 1.8rem;
				margin-bottom: 2.4rem;
	
				@media (max-width: $md){
					margin-top: 1.3rem;
					margin-bottom: 1.2rem;
				}
			}
		}

		
	}

	@media (max-width: $md){
		margin-bottom: 3.7rem;
	}

	&:has(.read-more.open){
		z-index: 2;
	}

	.inner{
		position: relative;
		text-align: center;

		img{
			margin-bottom: 1.2rem;
			width: 21rem;
			margin-left: auto;
			margin-right: auto;

			@media (max-width: $md){
				margin-bottom: 1.2rem;
				width: 14.4rem;
			}
		}

		h4{
			padding: 0 2rem;
			margin-bottom: .12em;

			@media (max-width: $md){
				@include font-size(20);
				@include line-height(20,22);
			}
		}

		p.large{
			padding: 0 2rem;
			color: $form;
			margin-bottom: .54em;

			@media (max-width: $md){
				@include font-size(18);
				@include line-height(18,23.4);
				margin-bottom: .74em;
			}
		}

		.btn{
			margin: 2rem 2rem 0;
			padding-left: 1.9rem;
			padding-right: 1.9rem;

			@media (max-width: $md){
				margin: .9rem 2rem 0;
			}
		}
		
		.read-more{
			padding: 0 2rem;
			text-decoration: none;
			padding-right: 1.7rem;
			position: relative;

			@media (max-width: $md){
				padding: 0 .5rem;
				padding-right: 1rem;
			}

			&.open{

				+ .bio{
					opacity: 1;
					visibility: visible;
				}
			}

			&:hover,
			&:focus-visible{
				text-decoration: underline;
			}
		}

		.bio{
			position: absolute;
			top: calc(100% + 6.3rem);
			top: 0;
			left: 0;
			background-color: $grey;
			padding: 2.4rem 3rem 2rem;
			border-radius: 1rem;
			width: 100%;
			opacity: 0;
			visibility: hidden;
			transition: $time;
			max-height: 38rem;
			text-align: left;
			
			@media (min-width: $md + 1){
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}

			@media (max-width: $md){
				left: 0rem;
				top: calc(100% + 2.3rem);
				padding: 1.4rem 1.4rem 0rem;
				position: fixed;
				top: 50%;
				width: calc(100dvw - 3rem);
				max-width: calc(100dvw - 3rem);
				left: 0rem;
				transform: translate(0%, -50%);
				box-shadow: 0 0 0 100vh rgba($black,.8);
			}

			.read-close{
				margin: 2rem auto;
				text-align: center;
				display: block;
				text-decoration: none;

				&:hover,
				&:focus-visible{
					text-decoration: underline;
				}
			}

			.inner-inner{
				max-height: 28.6rem;
				overflow: auto;
				scrollbar-width: initial!important;
				padding-right: .5rem;

				&::-webkit-scrollbar{
					width: .5rem;
				}

				&::-webkit-scrollbar-track{
					background: rgba($black,.2); 
				}

				&::-webkit-scrollbar-thumb{
					background: rgba($black,.7);
					cursor: pointer;
				}

				p,
				li{
					@include font-size(14);
					@include line-height(14,25);
				}
			}
		}
	}
}

.spotlight-section{

	&.black{

		// .w-item .inner p{
		// 	color: $black;
		// }
	}

	.container{
		max-width: 137.6rem;
	}

	.row{
		padding-top: 6rem;
		padding-bottom: 8.2rem;

		@media (max-width: $md){
			padding-top: 2.6rem;
			padding-bottom: 3.6rem;
		}
	}

	.title-con{
		text-align: center;
		margin-bottom: 6.1rem;

		@media (min-width: $md + 1){
			
			h4{
				@include font-size(56);
				@include line-height(56,60);
			}
		}

		@media (max-width: $md){
			margin-bottom: 2.5rem;
		}
	}

	.w-item{
		margin-bottom: 0;

		.inner{

			.fluid-width-video-wrapper,
			video,
			img{

				@media (min-width: $md + 1){
					margin-bottom: 5.4rem;
					aspect-ratio: 1356/699;
				}
			}

			.fluid-width-video-wrapper{
				aspect-ratio: 1356/763;
			}

			h4{

				@media (min-width: $md + 1){
					margin-bottom: .14em;
				}
			}

			// .cats{
	
			// 	.cat{
			// 		border-color: white;
			// 		color: white;
			// 	}
			// }
		}
	}
}

.w-item{
	margin-bottom: 6.3rem;

	@media (max-width: $md){
		margin-bottom: 3.1rem;
	}

	.inner{
		position: relative;

		a.over{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 2.5rem 2.5rem 0 0;
			z-index: 2;
			@include font-size(0);

			&:hover,
			&:focus-visible{
				
				~ h6,
				~ h4{
					text-decoration: underline;
				}
			}
		}

		.fluid-width-video-wrapper,
		video,
		img{
			border-radius: 2.5rem;
			margin-bottom: 2.1rem;
			aspect-ratio: 1/1;
			object-fit: cover;
			object-position: 50% 50%;
			width: 100%;

			@media (max-width: $md){
				border-radius: 1.5rem;
				margin-bottom: 1.1rem;
			}

			&:has(+ h6){
				margin-bottom: 1.7rem;

				@media (max-width: $md){
					margin-bottom: 1rem;
				}
			}
		}

		.fluid-width-video-wrapper{
			overflow: hidden;
		}

		h4{
			margin-bottom: 0;
		}

		h6{
			margin-bottom: 0;

			@media (max-width: $md){
				@include font-size(18);
				@include line-height(18,23.4);
			}

			~ p{
				margin-bottom: 1.1rem;
			}

			~ .cats{
				margin-top: 1.1rem;
			}
		}

		p{
			margin-bottom: 1.3rem;
			color: $form;

			@media (max-width: $md){
				margin-bottom: 1rem;
			}
		}

		.cats{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			margin-top: 1.3rem;

			@media (max-width: $md){
				margin-top: 1rem;
			}

			.cat{
				border: .1rem solid $grey;
				background-color: $grey;
				@include font-size(12);
				padding: .45rem .7rem;
				border-radius: .4rem;
				margin-bottom: 1rem;
				color: $black;

				// @media (max-width: $md){
				// 	@include font-size(10);
				// }

				&:has(+ .cat){
					margin-right: 1rem;
				}
			}
		}
	}
}

.work-archive-section{

	.container{
		max-width: 138rem;
	}

	.row{
		padding-top: 1.9rem;
		padding-bottom: 8.1rem;

		@media (max-width: $md){
			padding-bottom: 6.3rem;
		}
	}

	.text{
		text-align: center;

		h2{
			width: 108rem;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.filter-con{
		margin-top: 5.1rem;
		margin-bottom: 4.9rem;
		justify-content: center;

		@media (max-width: $md){
			margin-top: 1.9rem;
			margin-bottom: 3.2rem;
		}

	}

	.facetwp-template{
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}

	.facetwp-facet-pages{

		@media (max-width: $md){
			margin-top: 2rem;
		}
	}
}

.video-section{

	.container{
		max-width: 137.8rem;
	}

	.row{
		padding-bottom: 9.6rem;

		@media (max-width: $md){
			padding-bottom: 7.2rem;
		}
	}

	.fluid-width-video-wrapper,
	img,
	video{
		border-radius: 2.5rem;
		width: 100%;
		overflow: hidden;

		@media (max-width: $md){
			border-radius: 1.5rem;
		}
	}
}

.single-image-section,
.double-images-section,
.triple-images-section{


	&:has(+ .text-with-points-section){
		
		.row{
			padding-bottom: 12rem;

			@media (max-width: $md){
				padding-bottom: 5.2rem;
			}
		}
	}

	& + .text-with-points-section{

		.row{
			padding-top: 0;
		}
	}
}

.text-with-points-section{

	.container{
		max-width: 138rem;
	}

	.row{
		padding-top: 10rem;
		padding-bottom: 10rem;

		@media (max-width: $md){
			padding-bottom: 3.9rem;
		}
	}

	.text-side{

		@media (max-width: $md){
			margin-bottom: 2.2rem;
		}
	}

	.points-side{

		@media (min-width: $md + 1){
			padding-left: 12.5rem;
			padding-top: .4rem;
		}

		.point{
			margin-bottom: 1rem;
			border: .2rem solid $black;
			padding: 1.3rem 2.7rem;
			@include font-size(36);
			@include line-height(36,40);
			border-radius: 3.5rem;
			width: auto;
			display: inline-block;
			margin-right: 1rem;

			@media (max-width: $md){
				@include font-size(18);
				@include line-height(18,23.4);
				padding: 1rem 1.5rem;
				margin-bottom: .6rem;
			}

			&:last-of-type{
				margin-bottom: 0;
			}
		}
	}
}

.single-image-section,
.double-images-section,
.triple-images-section{


	&:has(+ .quote-section){
		
		.row{
			padding-bottom: 10.5rem;

			@media (max-width: $md){
				padding-bottom: 5rem;
			}
		}
	}
}

.quote-section{

	&.white{

		.row{
			padding-top: 11.5rem;
			padding-bottom: 12.5rem;

			@media (max-width: $md){
				padding-top: 8.5rem;
				padding-bottom: 8.4rem;
			}
		}

		.quote{

			.quote-side{
				width: 87.1rem;
				padding: 4.5rem 5rem 4.5rem 13.1rem;

				@media (max-width: $md){
					width: 100%;
					padding: 6.1rem 1.9rem 2.3rem 1.9rem;
				}
			}

			h4{

				@media (max-width: $md){
					margin-bottom: .8em!important;
				}
			}
		}
	}

	&.black{

		.row{

			.single-sectors &{
				padding-bottom: 8.9rem;
			}
		}

		.quote-con{
			justify-content: flex-end;
		}

		.quote{
			
			.logo-side{
				background-color: $white;
			}

			.quote-side{
				border-color: $white;

				.single-sectors &{
					width: 115rem;
					padding: 3.5rem 4.6rem 2.9rem 15.3rem;

					@media (max-width: $md){
						width: 100%;
						padding: 6.1rem 0.8rem 1.7rem 2rem;
					}
				}
			}
		}
	}

	.container{
		max-width: 137.6rem;
	}

	.row{
		padding-top: 6rem;
		padding-bottom: 10.5rem;

		@media (max-width: $md){
			padding-top: 11.3rem;
			padding-bottom: 10.5rem;
		}
	}

	.quote-con{
		display: flex;
		justify-content: center;
	}

	.quote{
		display: flex;
		justify-content: center;
		align-items: center;

		@media (max-width: $md){
			position: relative;
		}

		.logo-side{
			width: 19.6rem;
			min-width: 19.6rem;
			aspect-ratio: 1/1;
			border-radius: 50%;
			background-color: $black;
			margin-right: -9.8rem;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 1.3rem;
			z-index: 2;

			img{
				max-width: 70%;

				@media (max-width: $md){
					max-width: 80%;
				}
			}

			@media (max-width: $md){
				position: absolute;
				top: 0;
				left: 2rem;
				width: 10rem;
				min-width: 10rem;
				transform: translateY(-50%);

			}
		}

		.quote-side{
			border-radius: 2.5rem;
			border: .2rem solid $black;
			padding: 3.4rem 3rem 4.2rem 13.3rem;
			width: 76.6rem;

			@media (max-width: $md){
				border-radius: 1.5rem;
				width: 100%;
				padding: 6.1rem 0.8rem 1.7rem 2rem;
			}

			h4{
				margin-bottom: .42em;

				@media (max-width: $md){
					@include font-size(20);
					@include line-height(20,22);
				}
			}
		}
	}
}

.featured-work-section{

	.single-services &{

		.row{
			padding-top: .8rem;
			padding-bottom: 1.9rem;

			@media (max-width: $md){
				padding-top: 3.6rem;
				padding-bottom: 5.4rem;

				.btn-side{
					align-self: center;
				}
			}
		}
	}

	.single-work &{

		.row{
			padding-bottom: 9.9rem;
		}
	}

	.container {
		max-width: 137.6rem;
	}

	.row{
		padding-top: 6rem;
		padding-bottom: 3.3rem;

		@media (max-width: $md){
			padding-top: 3.5rem;
			padding-bottom: 3.3rem;

			.single-sectors &{
				padding-bottom: 6.4rem;
			}
		}
	}

	.title-side{
		margin-bottom: 3.5rem;

		@media (max-width: $md){
			margin-bottom: 2.2rem;
			align-self: center;
			flex: 0 0 100%;
			max-width: 100%;

			h3{
				letter-spacing: -.02em;
				@include font-size(24);
				@include line-height(24,28);
			}
		}
	}

	.btn-side{

		@media (max-width: $md){
			flex: 0 0 100%;
			max-width: 100%;
			order: 9;
			
		}
	}

	.btn-con{
		margin-top: .6rem;

		@media (max-width: $md){
			margin-top: 0;
			margin-bottom: 2.2rem;
			margin-bottom: 0;
			margin-top: 2.2rem;
			justify-content: flex-start;
		}

		.btn{
			// padding-left: 2.9rem;
			// padding-right: 2.9rem;
		}
	}
}

.careers-archive-section{

	.col-12{
		position: static;
	}

	.hero-menu{
		position: absolute;
		top: -11.1rem;
		top: 10.1rem;
		left: -10.5rem;
		@include font-size(14);
		display: flex;
		align-items: center;

		&.parent-menu{

			.btn{

				&:nth-of-type(1){

					&.active,
					&:hover,
					&:focus-visible{
						background-color: $blue;
						border-color: $blue;
						color: $black!important;
					}
				}

				&:nth-of-type(2){

					&.active,
					&:hover,
					&:focus-visible{
						background-color: $purple;
						border-color: $purple;
						color: $black!important;
					}
				}

				&:nth-of-type(3){

					&.active,
					&:hover,
					&:focus-visible{
						background-color: $yellow;
						border-color: $yellow;
						color: $black!important;
					}
				}

				&:nth-of-type(4){

					&.active,
					&:hover,
					&:focus-visible{
						background-color: $green;
						border-color: $green;
						color: $black!important;
					}
				}

				&:nth-of-type(5){

					&.active,
					&:hover,
					&:focus-visible{
						background-color: $pink;
						border-color: $pink;
						color: $black!important;
					}
				}

				// &:nth-of-type(6),
				// &:nth-of-type(6) + a{
				// 	display: none;
				// }
			}
		}

		@media (max-width: $md){
			display: none;
		}

		.back,
		span{
			font-weight: 500;
			padding: 1.4rem 1.7rem 1.4rem 0;
			border-right: .1rem solid $black;
			order: 1;
			text-decoration: none;
		}

		.back{

			&:hover,
			&:focus-visible{
				color: $blue;
			}
		}

		.btn.small{
			font-size: 1.4rem !important;
			margin-left: 1.5rem;
			padding: .4rem .7rem;
			order: 1;

			&.green{

				&.active,
				&:hover,
				&:focus-visible{
					background-color: $green;
					border-color: $green;
					color: $black!important;
				}
			}
		
			&.yellow{
		
				&.active,
				&:hover,
				&:focus-visible{
					background-color: $yellow;
					border-color: $yellow;
					color: $black!important;
				}
			}
		
			&.blue{
		
				&.active,
				&:hover,
				&:focus-visible{
					background-color: $blue;
					border-color: $blue;
					color: $black!important;
				}
			}

			&.grey{
		
				&.active,
				&:hover,
				&:focus-visible{
					background-color: $grey-dark;
					border-color: $grey-dark;
					color: $black!important;
				}
			}
		
			&.purple{
		
				&.active,
				&:hover,
				&:focus-visible{
					background-color: $purple;
					border-color: $purple;
					color: $black!important;
				}
			}

			&.pink{
		
				&.active,
				&:hover,
				&:focus-visible{
					background-color: $pink;
					border-color: $pink;
					color: $black!important;
				}
			}

			&.active{
				pointer-events: none;
				order: 1;
			}
		}
	}

	.container{
		max-width: 115rem;
	}

	.row{
		padding-top: 22.1rem;
		padding-bottom: 18.6rem;

		@media (max-width: $md){
			padding-top: 10.2rem;
			padding-bottom: 5.3rem;
		}
	}

	.text{
		text-align: center;

		h2{
			
			@media (max-width: $md){
				@include font-size(36);
				@include line-height(36,36);
			}
		}
	}

	.filter-con{
		margin-top: 7.9rem;
		margin-bottom: 7rem;
		justify-content: center;

		@media (max-width: $md){
			margin-top: 3.3rem;
			margin-bottom: 2.3rem;
		}

	}

	.facetwp-template{
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}
}

.ca-item{
	margin-bottom: 3.3rem;

	@media (max-width: $md){
		margin-bottom: 2.5rem;
	}

	&.grey{
		margin-top: 3.3rem;

		.inner{
			background-color: $grey;

			p{
				width: 72rem;

				@media (max-width: $md){
					width: 26rem;
				}
			}
		}
	}

	&.black{
		margin-top: 3.2rem;

		@media (max-width: $md){
			margin-top: 2.5rem;
		}

		.inner{
			background-color: $black;
			
			*{
				color: $white;
			}

			p{
				width: 70rem;
			}

			.btn{
				border-color: $white;
				background-color: $white;
				color: $black!important;
				text-decoration: underline!important;

				&:hover,
				&:focus-visible{
					border-color: $yellow;
					background-color: $yellow;
				}
			}
		}
	}

	.inner{
		padding: 2.9rem 3.1rem 3.1rem;
		border-radius: 2.5rem;
		border: .2rem solid $black;
		display: flex;
		flex-wrap: wrap;
		position: relative;

		@media (max-width: $md){
			padding: 1.4rem 1.6rem 1.7rem;
			border-radius: 1.5rem;
		}

		a.over{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 2.5rem;
			z-index: 3;
			@include font-size(0);

			&:hover,
			&:focus-visible{
				
				~ .btn-con .btn{
					background-color: $black;
					color: $white!important;
				}
			}
		}

		.text-side{
			width: calc(100% - 16.1rem);

			@media (min-width: $md + 1){
				padding-right: 1rem;
			}

			@media (max-width: $md){
				width: 100%;
			}
		}

		.info-side{
			width: 16.1rem;
			
			@media (min-width: $md + 1){
				padding-top: .14rem;
			}

			@media (max-width: $md){
				width: 100%;
				margin-top: 1.4rem;
				display: flex;
				flex-wrap: wrap;
			}

			.info{
				border: .1rem solid $black;
				@include font-size(14);
				border-radius: 2.5rem;
				padding: .45rem .7rem;
				margin-bottom: .9rem;
				display: inline-block;

				@media (max-width: $md){
					@include font-size(13);
					padding: .25rem .6rem;
					margin-right: 1rem;
				}
			}
		}

		h3{
			margin-bottom: .345em;
		}

		p{
			width: 64rem;
		}

		.btn-con{
			margin-top: 3rem;

			@media (max-width: $md){
				margin-top: 3.2rem;
			}
		}
	}
}

body.single-resources .text-with-benefits-section{

	.row{
		padding-top: 6.6rem;
		padding-bottom: 7.9rem;

		@media (max-width: $md){
			padding-top: 2.6rem;
			padding-bottom: 4.3rem;
		}
	}

	.text-side{

		@media (min-width: $md + 1){
			flex: 0 0 41.7%;
			max-width: 41.7%;
			padding-right: 15rem;
		}
	}

	.benefits-side{

		@media (min-width: $md + 1){
			flex: 0 0 58.3%;
			max-width: 58.3%;
		}
	}
}

.text-with-benefits-section{

	&:has(+ .applications-section){

		.row{
			padding-bottom: 6.1rem;
		}
	}

	&.black{

		.benefits-side{
	
			.benefit{
				border-bottom-color: $white;
			}
		}
	}

	.row{
		padding-top: 4.7rem;
		padding-bottom: 5.6rem;

		@media (max-width: $md){
			padding-top: 2.5rem;
			padding-bottom: 3.3rem;
		}
	}

	.text-side{

		@media (min-width: $md + 1){
			padding-right: 21rem;
		}
	}

	.benefits-side{

		@media (min-width: $md + 1){
			padding-right: 12.5rem;
		}

		.benefit{
			border-bottom: 1px solid $black;
			padding: 2.46rem 0;
			
			@media (max-width: $md){
				padding: 1.96rem 0;
				
				p.large{
					@include font-size(18);
					@include line-height(18,23.4);
				}
			}

			&:first-of-type{
				padding-top: .9rem;

				@media (max-width: $md){
					padding-top: 3.2rem;
				}
			}

			&:last-of-type{
				border-bottom: none;
			}
		}
	}

	.btn-con{
		margin-top: 3.7rem;
	}

	h3{
		margin-bottom: .4em;

		@media (max-width: $md){
			letter-spacing: -.02em;
			@include font-size(24);
			@include line-height(24,28);
		}
	}
}



.text-with-benefits-section + .text-with-image-section{

	.row{
		align-items: flex-start;
		padding-top: 6rem;

		@media (max-width: $md){
			padding-top: 8rem;
		}

	}
}

.text-with-image-section{

	.row{
		padding-top: 8rem;
		padding-bottom: 10.8rem;
		align-items: center;

		@media (max-width: $md){
			padding-top: 4.4rem;
			padding-bottom: 6.3rem;
		}
	}

	.text-side{

		@media (min-width: $md + 1){
			padding-right: 10.5rem;
			padding-bottom: 1.8rem;
		}

		@media (max-width: $md){
			margin-bottom: 2.5rem;
			
			h3{
				letter-spacing: -.02em;
				@include font-size(24);
				@include line-height(24,28);
				margin-bottom: .65em;
			}
		}
	}

	.image-side{

		@media (min-width: $md + 1){
			padding-right: 5.6rem;
		}

		img{
			width: 100%;
			border-radius: 2.5rem;

			@media (max-width: $md){
				border-radius: 1.5rem;
			}
		}
	}

	h3{
		margin-bottom: .2em;
	}
}

.single-image-section,
.double-images-section,
.triple-images-section{


	&:has(+ .title-with-workspace-section){
		
		.row{
			padding-bottom: 3.9rem;
			
			@media (max-width: $md){
				padding-bottom: 2.8rem;
			}
		}
	}
}

.title-with-workspace-section{

	.row{
		padding-top: 2.5rem;
		padding-bottom: 9.3rem;

		@media (max-width: $md){
			padding-top: .5rem;
			padding-bottom: 6.8rem;
		}
	}

	.title-side{
		
		@media (max-width: $md){
			margin-bottom: 2rem;
		}
	}

	.text-side{

		@media (min-width: $md + 1){
			padding-right: 21rem;
		}
	}

	.workspace-side{

		@media (min-width: $md + 1){
			padding-right: 12.5rem;
			padding-top: 1.7rem;
		}

		.workspace{
			border-bottom: 1px solid $white;
			padding: 2.46rem 0;

			@media (max-width: $md){
				padding: 1.96rem 0;
				
				p.large{
					@include font-size(18);
					@include line-height(18,23.4);
				}
			}

			&:first-of-type{
				padding-top: 1.6rem;

				@media (max-width: $md){
					padding-top: .7rem;
				}
			}

			&:last-of-type{
				border-bottom: none;
			}
		}
	}

	h3{
		margin-bottom: .4em;

		@media (max-width: $md){
			letter-spacing: -.02em;
			@include font-size(24);
			@include line-height(24,28);
		}
	}
}

.career-information-section{

	.container{
		max-width: 114.8rem;
	}

	.row{
		padding-top: 6.2rem;
		padding-bottom: 6rem;

		@media (max-width: $md){
			padding-top: 2.9rem;
			padding-bottom: 2.5rem;
		}
	}

	.info-side{

		@media (min-width: $md + 1){
			flex: 0 0 40%;
			max-width: 40%;
		}

		h4{
			margin-bottom: 1.05em;

			@media (max-width: $md){
				margin-bottom: .8em;
				@include font-size(20);
				@include line-height(20,22);
			}
		}

		p.small{
			@include font-size(14);
			margin-bottom: .5em;
		}

		.info-con{
			display: flex;
			align-items: flex-start;
			flex-wrap: wrap;
			flex-direction: column;

			@media (max-width: $md){
				margin-bottom: 2rem;
			}

			.info{
				border: .1rem solid $white;
				@include font-size(14);
				border-radius: 2.5rem;
				padding: .3rem 1.1rem;
				margin-bottom: 1.3rem;
				display: inline-block;
				margin-right: 1.3rem;

				@media (max-width: $md){
					@include font-size(13);
					padding: .35rem .8rem;
					margin-bottom: 1rem;
				}
			}
		}

		.person-con{
			display: flex;
			align-items: center;
			width: 100%;
			margin-top: .6rem;
			margin-bottom: 3rem;

			@media (max-width: $md){
				margin-bottom: 3rem;
			}

			.img-con{
				width: 10rem;
				margin-left: -.4em;
				margin-right: .8em;

				@media (max-width: $md){
					width: 6.1rem;
					margin-right: .7em;
				}

				+ .person-info{
					width: calc(100% - 10rem);

					@media (max-width: $md){
						width: calc(100% - 6.5rem);
					}
				}
			}

			.person-info{
				width: 100%;

				small{
					margin-bottom: -.1em;
					display: block;

					@media (max-width: $md){
						@include font-size(13);
					}
				}

				p{
					color: $form;

					&.large{
						margin-bottom: 0;
						color: $white;

						@media (max-width: $md){
							@include font-size(18);
							@include line-height(18,23.4);
						}
					}
				}
			}
		}
	}

	.points-side{

		@media (min-width: $md + 1){
			padding-top: .1rem;
			flex: 0 0 60%;
			max-width: 60%;
		}

		> p{
			margin-bottom: .97em;

			&.large{
				margin-bottom: .8em;
				width: 56rem;
			}
		}

		.point{
			border-bottom: 1px solid $white;
			padding: 2.46rem 0;

			@media (max-width: $md){
				padding: 1.96rem 0;	
				
				p.large{
					@include font-size(18);
					@include line-height(18,23.4);
				}
			}

			&:first-of-type{
				padding-top: 0;

				@media (max-width: $md){
					padding-top: 1.6rem;
				}
			}

			&:last-of-type{
				border-bottom: none;
			}
		}
	}
}

.title-with-bullet-points-section{

	.row{
		padding-top: 3.9rem;
		padding-bottom: 8.8rem;

		@media (max-width: $md){
			padding-top: 3.3rem;
			padding-bottom: 2.3rem;
		}
	}

	.title-con{

		@media (max-width: $md){
			margin-bottom: 4.7rem;
		}

		+ .points{

			@media (min-width: $md + 1){
				margin-top: .7rem;
			}
		}
	}

	.points{
		padding: 0 1rem;
		width: 100%;

		@media (min-width: $md + 1){
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between
		}

		.point{
			border-bottom: 1px solid $black;
			width: 100%;
			padding-top: 3rem;
			padding-bottom: 3rem;
			break-inside: avoid;
			min-height: 8.6rem;
			display: flex;
			align-items: center;

			@media (max-width: $md){
				padding-top: 2rem;
				padding-bottom: 2rem;
				min-height: 0rem;

				&:last-of-type{
					border-bottom: none;
				}
			}

			@media (min-width: $md + 1){
				width: calc(50% - 1rem);

				&:nth-child(2n+1):nth-last-child(-n+2),
				&:nth-child(2n+1):nth-last-child(-n+2) ~ .point{
					border-bottom: none;
				}
			}

			span{
				padding-left: 1.5em;
				position: relative;
				padding-right: 2rem;

				&:before{
					content: '';
					position: absolute;
					left: .65em;
					top: .525em;
					width: .26em;
					aspect-ratio: 1/1;
					border-radius: 100%;
					background-color: currentColor;
				}
			}
		}
	}
}

.applications-section{

	.row{
		padding-top: 8.8rem;
		padding-bottom: 8.2rem;

		@media (max-width: $md){
			padding-top: 2.7rem;
			padding-bottom: 6.9rem;
		}
	}

	.title-side{

		@media (max-width: $md){
			margin-bottom: 2rem;
		}
	}

	.application-side{

		@media (min-width: $md + 1){
			padding-top: .3rem;
			padding-right: .8rem;
		}

		.gform_wrapper{
			margin-top: 4.3rem;

			@media (max-width: $md){
				margin-top: 3.5rem;
			}
		}

		.gform_footer{
			margin-top: 1rem;
		}
	}
}

.featured-post-section{

	.container{
		max-width: 137.6rem;
	}

	.row{
		padding-top: 4.6rem;
		padding-bottom: 7.5rem;

		@media (max-width: $md){
			padding-top: 2.8rem;
			padding-bottom: 6rem;
		}
	}

	.title-con{
		text-align: center;
		margin-bottom: 3.9rem;

		@media (max-width: $md){
			margin-bottom: 1.9rem;
			
			h4{
				letter-spacing: -.02em;
				@include font-size(24);
				@include line-height(24,28);
			}
		}
	}

	.in-item{
		margin-bottom: 0;

		.inner{

			img{

				@media (min-width: $md + 1){
					margin-bottom: 4.7rem;
					aspect-ratio: 1356/721;
				}

				@media (max-width: $md){
					aspect-ratio: 353/187.69;
				}
			}

			h4{

				@media (min-width: $md + 1){
					margin-bottom: .15em;
				}
			}

			.info{
	
				.read{
					border-color: white;
					color: white;
				}
			}
		}
	}
}

.news-archive-section,
.insight-archive-section{

	@media (min-width: $md + 1){
		
		.container,
		.col-4,
		.col-12{
			padding-right: 3.15rem;
			padding-left: 3.15rem;
		}

		.row{
			margin-right: -3.15rem;
			margin-left: -3.15rem;
		}
	}

	.container{
		max-width: 142.2rem;
	}

	.row{
		padding-top: 3.4rem;
		padding-bottom: 8.1rem;

		@media (max-width: $md){
			padding-top: 1.7rem;
			padding-bottom: 6rem;
		}
	}

	.text{
		text-align: center;

		h2{
			width: 108rem;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.filter-con{
		margin-bottom: 2.5rem;

		small{
			width: 100%;
			margin-bottom: 0.7rem;

			@media (max-width: $md){
				@include font-size(14);
			}
		}

		.facetwp-facet{
			margin-bottom: 1rem;
		}

		select{
			@include font-size(14);
			line-height: 2.9rem - .1rem - .1rem;
			border-width: .1rem;
			height: 2.9rem;
			padding-left: 1rem;
			padding-right: 2.2rem;
			background-size: 1.1rem auto;
			background-position: right .6rem top 50%;
		}

		.facetwp-type-dropdown{
			margin-right: .9rem;

			&:last-of-type{
				margin-right: 0;
			}
		}

	}

	.facetwp-template{
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}

	.facetwp-facet-pages{
		margin-top: 3.3rem;
	}
}

.in-item{
	margin-bottom: 3.7rem;

	@media (max-width: $md){
		margin-bottom: 2.3rem;
	}

	.inner{
		position: relative;

		a.over{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 2.5rem 2.5rem 0 0;
			z-index: 2;
			@include font-size(0);

			@media (max-width: $md){
				border-radius: 1.5rem 1.5rem 0 0;
			}

			&:hover,
			&:focus-visible{
				
				~ h6,
				~ h4{
					text-decoration: underline;
				}
			}
		}

		img{
			border-radius: 2.5rem;
			margin-bottom: 2.1rem;
			aspect-ratio: 397/280;
			object-fit: cover;
			object-position: 50% 50%;
			width: 100%;

			@media (max-width: $md){
				border-radius: 1.5rem;
				aspect-ratio: 352/248.26;
			}

			&:has(+ h6){
				margin-bottom: 2rem;

				@media (max-width: $md){
					margin-bottom: 1.6rem;
				}
			}
		}

		h4{
			margin-bottom: 0;
		}

		h6{
			margin-bottom: .3em;

			@media (max-width: $md){
				margin-bottom: .4em;
			}

			~ p{
				margin-bottom: 1.7rem;

				@media (max-width: $md){
					margin-bottom: 1.4rem;
				}
			}
		}

		p{
			margin-bottom: 1.3rem;
			color: $form;

			@media (max-width: $md){
				margin-bottom: 1.4rem;
			}
		}

		.info{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			margin-top: 1.3rem;

			@media (max-width: $md){
				margin-top: 1.4rem;
			}

			.read{
				border: .1rem solid $black;
				@include font-size(14);
				padding: .45rem .7rem;
				border-radius: 1.45rem;
				margin-bottom: 1rem;
				margin-right: .8rem;

				@media (max-width: $md){
					@include font-size(13);
					padding: .4rem .8rem;
					margin-right: .9rem;
				}
			}

			.by{
				@include font-size(14);
				margin-bottom: 1rem;

				@media (max-width: $md){
					@include font-size(13);
				}
			}
		}
	}
}

.insightnews-post-section{

	&.events{

		.row{
			padding-bottom: 12.2rem;

			@media (max-width: $md){
				padding-bottom: 5.2rem;
			}
		}

		.img-top-con{

			@media (max-width: $md){
				margin-bottom: 4.3rem;
			}

			img{

				@media (max-width: $md){
					aspect-ratio: 276.58/246.82;
				}
			}
		}

		.info-side{

			@media (max-width: $md){
				display: flex;
				align-items: flex-start;

				.share{
					margin-top: 1.1rem;

					p{
						margin-bottom: .7rem;
					}

					.share-link.share-link{
						width: 2.9rem;
						margin: 0 .9rem 1.1rem 0;
						@include font-size(20);

						&:last-of-type{
							margin-right: 0;
						}
					}
				}
			}
		}

		+ .text-with-benefits-section{

			.row{
				padding-top: 6.7rem;
				padding-bottom: 7.6rem;

				@media (max-width: $md){
					padding-top: 3.6rem;
					padding-bottom: 3.5rem;
				}
			}

			.text-side{

				@media (min-width: $md + 1){
					flex: 0 0 41.7%;
					max-width: 41.7%;
					padding-right: 10rem;
				}
			}

			.benefits-side{

				@media (min-width: $md + 1){
					flex: 0 0 58.3%;
					max-width: 58.3%;
				}
			}
		}

		.img-top-con{
			margin-bottom: 7.9rem;
		}

		.info-side{

			@media (min-width: $md + 1){
				flex: 0 0 41.7%;
				max-width: 41.7%;
			}

			img,
			svg{
				width: 32.1rem;
				margin-top: 1.1rem;
				margin-bottom: 4.6rem;

				@media (max-width: $md){
					width: 17.3rem;
					margin-right: 3.7rem;
					margin-bottom: 3rem;
				}
			}
		}

		.text-side{

			@media (min-width: $md + 1){
				flex: 0 0 58.3%;
				max-width: 58.3%;
				padding-right: 11rem;
			}

			h2{
				margin-bottom: .8em;
			}
		}
	}

	.row{
		padding-top: 13.2rem;
		padding-bottom: 6rem;

		@media (max-width: $md){
			padding-top: 8.7rem;
			padding-bottom: 1.9rem;
		}
	}

	.img-top-con{
		margin-bottom: 3.5rem;

		@media (max-width: $md){
			margin-bottom: 1.9rem;
		}

		img{
			//aspect-ratio: 1360/547;
			object-fit: cover;
			object-position: 50% 50%;
			border-radius: 2.5rem;
			width: 100%;

			@media (max-width: $md){
				border-radius: 1.5rem;
				aspect-ratio: 386.58/246.82;
			}
		}
	}

	.info-side{

		@media (min-width: $md + 1){
			flex: 0 0 33.5%;
			max-width: 33.5%;
		}

		@media (max-width: $md){
			display: none;
		}

		h4{
			margin-bottom: .3em;
		}

		.info-con{
			display: flex;
			align-items: flex-start;
			flex-wrap: wrap;
		}

		.person-con{
			display: flex;
			align-items: center;
			width: 100%;
			margin-top: .3rem;
			margin-bottom: 2.5rem;

			~ p{
				margin-bottom: 1rem;
			}

			.img-con{
				width: 13.4rem;
				margin-left: -.4em;
				margin-right: .1em;

				img{
					width: 12.8rem;
				}

				+ .person-info{
					width: calc(100% - 13.4rem);
				}
			}

			.person-info{
				width: 100%;
				margin-top: 1.7rem;

				p{

					&.large{
						margin-bottom: 0;
					}
				}

				.info{
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					margin-top: .9rem;
		
					.read{
						border: .1rem solid $black;
						@include font-size(14);
						padding: .45rem .7rem;
						border-radius: 1.45rem;
						margin-bottom: 1rem;
						margin-right: .8rem;
					}
		
					.date{
						@include font-size(14);
						margin-bottom: 1rem;
					}
				}
			}
		}

		.share{
			margin-top: 2.8rem;
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			p{
				margin-bottom: .8rem;
				width: 100%;
			}

			.share-link.share-link{
				width: 3.8rem;
				aspect-ratio: 1/1;
				border-radius: 3.8rem;
				margin: 0 1.1rem 1.1rem 0;
				justify-content: center;
				align-items: center;
				color: $white;
				text-align: center;
				@include font-size(24);

				&:hover,
				&:focus-visible{
					background-color: $yellow!important;
					color: $black;
				}

				i{
					margin: 0;
				}

				&.facebook{
					background-color: #1877f2;
				}

				&.twitter{
					background-color: #212121;
				}

				&.linkedin{
					background-color: #007bb5;
				}

				&.mail{
					background-color: #16B988;

					i{
						transform: translateX(-.15rem);
					}
				}
			}
		}
	}

	.text-side{

		@media (min-width: $md + 1){
			padding-top: .1rem;
			flex: 0 0 66.5%;
			max-width: 66.5%;
			padding-right: 13rem;
		}

		> h3,
		> h4{
			width: 68rem;
		}

		> h3{
			margin-bottom: .9em;

			@media (max-width: $md){
				letter-spacing: -.02em;
				@include font-size(36);
				@include line-height(36,36);
				margin-bottom: .9em;
				
			}
		}

		> h4{
			margin-bottom: 1.34em;

			@media (max-width: $md){
				@include font-size(20);
				@include line-height(20,22);
				margin-bottom: .94em;
			}
		}
	}
}

.insightnews-post-section + .jump-to-section-section{

	.row{
		padding-top: 1.7rem;

		@media (max-width: $md){
			padding-top: 2.5rem;
		}
	}
}

.jump-to-section-section{

	.row{
		padding-top: 6rem;
		padding-bottom: 12.3rem;

		@media (max-width: $md){
			padding-bottom: 8rem;
		}
	}

	.hr-con{
		margin-bottom: 7.3rem;

		@media (max-width: $md){
			display: none;
		}
	}

	.info-side{

		@media (min-width: $md + 1){
			display: none;
		}

		h4{
			margin-bottom: .3em;
		}

		.info-con{
			display: flex;
			align-items: flex-start;
			flex-wrap: wrap;
		}

		.person-con{
			display: flex;
			align-items: center;
			width: 100%;
			margin-top: .3rem;
			margin-bottom: 2.5rem;

			~ p{
				margin-bottom: 1rem;
				width: auto;
				float: left;

				&:has(+ p){
					margin-right: 2.8rem;
				}

			}

			.img-con{
				width: 13.4rem;
				margin-left: -.4em;
				margin-right: .1em;

				img{
					width: 12.8rem;
				}

				+ .person-info{
					width: calc(100% - 11.4rem);
					margin-right: -2rem;
				}
			}

			.person-info{
				width: 100%;
				margin-top: 1.7rem;

				p{

					&.large{
						margin-bottom: 0;

						@media (max-width: $md){
							@include font-size(24);
							@include line-height(24,31);
						}
					}
				}

				.info{
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					margin-top: .9rem;
		
					.read{
						border: .1rem solid $black;
						@include font-size(14);
						padding: .45rem .7rem;
						border-radius: 1.45rem;
						margin-bottom: 1rem;
						margin-right: .6rem;
					}
		
					.date{
						@include font-size(14);
						margin-bottom: 1rem;
					}
				}
			}
		}

		.share{
			margin-top: 8rem;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			width: 100%;
			clear: both;

			p{
				margin-bottom: .8rem;
				width: 100%;
			}

			.share-link.share-link{
				width: 3.8rem;
				aspect-ratio: 1/1;
				border-radius: 3.8rem;
				margin: 0 1.1rem 1.1rem 0;
				justify-content: center;
				align-items: center;
				color: $white;
				text-align: center;
				@include font-size(24);

				&:hover,
				&:focus-visible{
					background-color: $yellow!important;
					color: $black;
				}

				i{
					margin: 0;
				}

				&.facebook{
					background-color: #1877f2;
				}

				&.twitter{
					background-color: #212121;
				}

				&.linkedin{
					background-color: #007bb5;
				}

				&.mail{
					background-color: #16B988;

					i{
						transform: translateX(-.15rem);
					}
				}
			}
		}
	}

	.titles-side{

		@media (min-width: $md + 1){
			padding-bottom: 8.297rem;
		}

		.side{
			border: .2rem solid $black;
			border-radius: 2.5rem;
			width: 39.2rem;
			padding: 1.4rem 1.9rem 2.3rem 1.9rem;
			counter-reset: section;
			position: sticky;
			top: 12.5rem;

			@media (max-width: $md){
				padding: 1.4rem 1.9rem .9rem 1.9rem;
				margin-bottom: 7.2rem;
				position: relative;
				top: 0;

				&:after{
					content: '';
					position: absolute;
					bottom: -3.7rem;
					left: 0rem;
					right: 0rem;
					height: 1px;
					background-color: $black;
				}
				
				p{
					@include font-size(20);
					@include line-height(20,26);
				}
			}
			
			a{
				position: relative;
				padding: .98rem 0 .98rem 4.7rem;
				display: block;
				text-decoration: none;
				border-bottom: 1px solid $black;
				@include font-size(24);

				@media (max-width: $md){
					@include font-size(18);
					@include line-height(18,23.4);
					padding: 1.28rem 0 1.28rem 4.7rem;
				}

				&:hover{
					text-decoration: underline;
				}

				&:first-of-type{
					border-top: 1px solid $black;
				}

				&:last-of-type{
					border-bottom: none;
				}

				&:nth-of-type(5n + 1){

					&:before{
						background-color: $green;
					}
				}
	
				&:nth-of-type(5n + 2){
	
					&:before{
						background-color: $purple;
					}
				}
	
				&:nth-of-type(5n + 3){
	
					&:before{
						background-color: $blue;
					}
				}
	
				&:nth-of-type(5n + 4){
	
					&:before{
						background-color: $yellow;
					}
				}
	
				&:nth-of-type(5n + 5){
	
					&:before{
						background-color: $pink;
					}
				}
	
				&:before{
					position: absolute;
					top: .4em;
					left: .15em;
					width: 3.2rem;
					height: 3.2rem;
					text-align: center;
					border-radius: 3.2rem;
					background-color: $green;
					line-height: 3.2rem;
					counter-increment: section;
					content: counter(section);

					@media (max-width: $md){
						top: .5em;
						@include font-size(20);
					}
				}
			}
		}
	}

	.text-side{
		counter-reset: section;

		.sections{
			position: relative;
			padding-left: 5.7rem;
			margin-bottom: 5rem;

			@media (max-width: $md){
				padding-left: 4.1rem;
				margin-bottom: 3.5rem;
			}

			&:nth-of-type(5n + 1){

				&:before{
					background-color: $green;
				}
			}

			&:nth-of-type(5n + 2){

				&:before{
					background-color: $purple;
				}
			}

			&:nth-of-type(5n + 3){

				&:before{
					background-color: $blue;
				}
			}

			&:nth-of-type(5n + 4){

				&:before{
					background-color: $yellow;
				}
			}

			&:nth-of-type(5n + 5){

				&:before{
					background-color: $pink;
				}
			}

			@media (min-width: $md + 1){
				padding-right: 11rem;
			}

			&:before{
				position: absolute;
				top: .2em;
				left: 0;
				width: 3.2rem;
				height: 3.2rem;
				text-align: center;
				border-radius: 3.2rem;
				background-color: $green;
				line-height: 3.2rem;
				counter-increment: section;
				content: counter(section);

				@media (max-width: $md){
					top: -.3em;
					@include font-size(20);
				}
			}
		}

		.share{
			margin-top: -.7rem;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			padding-left: 5.7rem;

			@media (max-width: $md){
				display: none;
			}

			p{
				margin-bottom: .8rem;
				width: 100%;
			}

			.share-link.share-link{
				width: 3.8rem;
				aspect-ratio: 1/1;
				border-radius: 3.8rem;
				margin: 0 1.1rem 1.1rem 0;
				justify-content: center;
				align-items: center;
				color: $white;
				text-align: center;
				@include font-size(24);

				&:hover,
				&:focus-visible{
					background-color: $yellow!important;
					color: $black;
				}

				i{
					margin: 0;
				}

				&.facebook{
					background-color: #1877f2;
				}

				&.twitter{
					background-color: #212121;
				}

				&.linkedin{
					background-color: #007bb5;
				}

				&.mail{
					background-color: #16B988;

					i{
						transform: translateX(-.15rem);
					}
				}
			}
		}
	}
}

.about-section{

	.row{
		padding-top: 4.6rem;
		padding-bottom: 9.8rem;

		@media (max-width: $md){
			padding-top: 2.6rem;
			padding-bottom: 4rem;

			h3{
				letter-spacing: -.02em;
				@include font-size(24);
				@include line-height(24,28);
			}
		}
	}

	.about-side{
		display: flex;
		align-items: flex-start;
		width: 100%;
		margin-top: .3rem;
		margin-bottom: 2.5rem;

		@media (max-width: $md){
			margin-top: 1.5rem;
		}

		p{
			padding-top: 1.1rem;

			@media (max-width: $md){
				padding-top: .2rem;
			}
		}

		.img-con{
			width: 15.9rem;
			margin-left: -.4em;

			@media (max-width: $md){
				width: 8rem;
				margin-right: 1.6rem;
			}

			img{
				width: 14.2rem;
			}

			+ .person-info{
				width: calc(100% - 15.9rem);

				@media (max-width: $md){
					width: calc(100% - 9.4rem);
				}
			}
		}
	}
}

.follow-section{

	.row{
		padding-top: 5.8rem;
		padding-bottom: 3.8rem;

		@media (max-width: $md){
			padding-top: 1.9rem;
			padding-bottom: 3.8rem;
		}
	}

	h3{
		margin-bottom: .2em;

		@media (max-width: $md){
			letter-spacing: -.02em;
			@include font-size(24);
			@include line-height(24,28);
		}
	}

	.title-side{

		@media (min-width: $md + 1){
			padding-right: 20rem;
		}

		@media (max-width: $md){
			margin-bottom: 2.7rem;
		}

		a{
			// text-decoration: underline;

			// // &:hover,
			// // &:focus-visible{
			// // 	text-decoration: underline;
			// // }
		}
	}

	.about-side{

		@media (min-width: $md + 1){
			padding-right: 20rem;
		}
	}
}

.download-form-section{

	.single-webinars &,
	.single-events &{

		@media (min-width: $md + 1){
			
			.row{
				padding-top: 12.2rem;
				padding-bottom: 13.1rem;
			}
		}
	}

	&:has(+ .clients-slide-section){

		.row{
			padding-bottom: 3.5rem;

			@media (max-width: $md){
				padding-top: 4.3rem;
			}
		}
	}

	.container{
		max-width: 91.8rem;
	}

	.row{
		padding-top: 6.2rem;
		padding-bottom: 8.9rem;

		@media (max-width: $md){
			padding-top: 3.6rem;
			padding-bottom: 7.1rem;
		}
	}

	.text-con{
		text-align: center;
		margin-bottom: 5.3rem;
		margin-top: -.6rem;

		@media (max-width: $md){
			margin-bottom: 2.1rem;
		}

		h3{
			width: 60rem;
			margin-left: auto;
			margin-right: auto;

			@media (max-width: $md){
				width: 32rem;
				letter-spacing: -.02em;
				margin-bottom: 1.1em;
				@include font-size(24);
				@include line-height(24,28);
			}
		}
	}

	.gform_confirmation_message{
		text-align: center;
	}

	.gform_confirmation_wrapper{

		& ~ .btn-con.download{
			display: flex;
		}
	}

	.form-con{

		.gform_heading{

			p{

				@media (min-width: $md + 1){
					column-count: 2;
					break-inside: avoid;
					width: 100%;
				}
			}
		}
	}

	.btn-con.download{
		display: none;
	}
}

.texts-and-center-image-section{

	.container{
		max-width: 127.8rem;
	}

	.row{
		padding-top: 12.2rem;
		padding-bottom: 16.1rem;
		align-items: center;

		@media (max-width: $md){
			padding-top: 2.6rem;
			padding-bottom: 7.3rem;
		}
	}

	.col-md-3{

		@media (max-width: $md){
			
			p.large{
				@include font-size(18);
				@include line-height(18,23.4);
			}
		}

		@media (min-width: $md + 1){
			flex: 0 0 28.6%;
			max-width: 28.6%;
			padding-bottom: 1rem;

			&:nth-of-type(1){
				padding-right: 6rem;
			}

			&:nth-of-type(3){
				padding-left: 4.6rem;
				padding-right: 0;
			}
		}
	}

	.col-md-6{

		@media (min-width: $md + 1){
			flex: 0 0 42.8%;
			max-width: 42.8%;
		}

		@media (max-width: $md){
			margin-top: 2.2rem;
			margin-bottom: 2.2rem;
		}
	}
}

.experts-section{

	> .container > .row{
		padding-top: 5rem;
		padding-bottom: 9.6rem;

		@media (max-width: $md){
			padding-top: 2.9rem;
			padding-bottom: 7.4rem;
		}
	}

	.text-side{
		
		@media (min-width: $md + 1){
			flex: 0 0 41.6%;
			max-width: 41.6%;
			padding-top: .7rem;
			padding-right: 16rem;
		}

		@media (max-width: $md){
			margin-bottom: 2.1rem;
			
			h3{
				letter-spacing: -.02em;
				@include font-size(24);
				@include line-height(24,28);
			}
		}
	}

	.experts-side{

		@media (min-width: $md + 1){
			flex: 0 0 58.4%;
			max-width: 58.4%;
		}
	}

	.t-item{
		margin-bottom: 4.7rem;

		@media (max-width: $md){
			margin-bottom: 4.3rem!important;

			&:last-of-type{
				margin-bottom: 0!important;
			}
		}

		&:nth-child(2n+1):nth-last-child(-n+2),
		&:nth-child(2n+1):nth-last-child(-n+2) ~ .t-item{
			margin-bottom: 0;
		}

		.inner{
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			@media (max-width: $md){
				text-align: left;
			}

			img{
				width: 9.6rem;
				margin-right: -1.2rem;

				@media (max-width: $md){
					width: 8.1rem;
					margin-right: 1.55rem;
				}
			}

			.text-con{
				width: calc(100% - 9.6rem - -1.2rem);

				@media (max-width: $md){
					width: calc(100% - 9.65rem);
				}
			}

			p{

				@media (max-width: $md){
					padding-left: 0;
					padding-right: 0;
				}

				&.large{
					margin-bottom: 0;

					@media (max-width: $md){
						@include font-size(18);
						@include line-height(18,23.44);
					}

					&:first-of-type{
						color: $white;
					}
				}
			}
		}
	}
}

.services-archive-section{

	.container{
		max-width: 129.8rem;
	}

	.row{
		padding-top: 6rem;
		padding-bottom: 6.2rem;
		align-items: center;

		@media (max-width: $md){
			padding-top: 3.9rem;
			padding-bottom: 7.9rem;
		}
	}
}

.ser-item{
	margin-bottom: 4rem;

	@media (max-width: $md){
		margin-bottom: 2.4rem;
	}

	@media (min-width: $md + 1){
		
		&:nth-child(2n+1):nth-last-child(-n+2),
		&:nth-child(2n+1):nth-last-child(-n+2) ~ .ser-item{
			margin-bottom: 0;
		}
	}

	&.green{

		.inner{

			.btn.btn.btn,
			.arrow-link{
				background-color: $green;
				border-color: $green;
			}
		}
	}

	&.purple{

		.inner{

			.btn.btn.btn,
			.arrow-link{
				background-color: $purple;
				border-color: $purple;
			}
		}
	}

	&.blue{

		.inner{

			.btn.btn.btn,
			.arrow-link{
				background-color: $blue;
				border-color: $blue;
			}
		}
	}

	&.yellow{

		.inner{

			.btn.btn.btn,
			.arrow-link{
				background-color: $yellow;
				border-color: $yellow;
			}
		}
	}

	.inner{
		position: relative;
		border-radius: 2.5rem;
		border: .2rem solid $white;
		padding: 3.5rem 3.8rem 3rem;

		@media (min-width: $md + 1){
			padding-right: 5rem;
		}

		@media (max-width: $md){
			border-radius: 1.5rem;
			padding: 2.5rem 2.6rem 2.6rem;
		}

		a.over{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 2.5rem 2.5rem 0 0;
			z-index: 2;
			@include font-size(0);

			&:hover,
			&:focus-visible{
				
				// ~ h6,
				// ~ h4{
				// 	text-decoration: underline;
				// }

				~ .arrow-link{
					background-color: $white;
					border-color: $white;
					margin-left: 2rem;
				}

				~ .btn.btn.btn{
					background-color: $white;
					border-color: $white;
					color: $black!important;
				}
			}
		}

		h4{
			margin-bottom: .29em;

			@media (max-width: $md){
				letter-spacing: -.02em;
				@include font-size(24);
				@include line-height(24,28);
				margin-bottom: .39em;
			}
		}

		p.large{
			margin-bottom: .9em;

			@media (max-width: $md){
				margin-bottom: 1.5em;
			}
		}

		.btn.btn.btn{
			background-color: $green;
			border: .2rem solid $green;
			color: $black!important;
		}

		.arrow-link{
			aspect-ratio: 1/1;
			width: 5rem;
			border-radius: 5rem;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: $green;
			border: .2rem solid $green;
			transition: $time;

			svg{
				aspect-ratio: 33/14.73;
				width: 3.3rem;

				*{
					transition: $time;
				}
			}
		}
	}
}

.sectors-archive-section{

	@media (min-width: $md + 1){

		.container,
		.col-4,
		.col-12{
			padding-right: 2.65rem;
			padding-left: 2.65rem;
		}

		.row{
			margin-right: -2.65rem;
			margin-left: -2.65rem;
		}
	}

	.container{
		max-width: 141.4rem;
	}

	.row{
		padding-top: 5.9rem;
		padding-bottom: 9.8rem;
		align-items: center;

		@media (max-width: $md){
			padding-top: 4.6rem;
			padding-bottom: 5.3rem;
		}
	}
}

.sec-item{
	margin-bottom: 5.2rem;

	@media (max-width: $md){
		margin-bottom: 2.1rem;
	}

	@media (min-width: $md + 1){
		
		&:nth-child(3n+1):nth-last-child(-n+3),
		&:nth-child(3n+1):nth-last-child(-n+3) ~ .sec-item{
			margin-bottom: 0;
		}
	}

	&.pink{

		.inner{

			.arrow-link{
				background-color: $pink;
				border-color: $pink;
			}
		}
	}

	&.yellow{

		.inner{

			.arrow-link{
				background-color: $yellow;
				border-color: $yellow;
			}
		}
	}

	&.grey{

		.inner{

			.arrow-link{
				background-color: $grey-dark;
				border-color: $grey-dark;
			}
		}
	}

	&.green{

		.inner{

			.arrow-link{
				background-color: $green;
				border-color: $green;
			}
		}
	}

	&.blue{

		.inner{

			.arrow-link{
				background-color: $blue;
				border-color: $blue;
			}
		}
	}

	&.purple{

		.inner{

			.arrow-link{
				background-color: $purple;
				border-color: $purple;
			}
		}
	}

	.inner{
		position: relative;
		border: .2rem solid $white;
		border-radius: 2.5rem;
		overflow: hidden;

		a.over{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 2.5rem 2.5rem 0 0;
			z-index: 2;
			@include font-size(0);

			&:hover,
			&:focus-visible{
				
				// ~ .text h6,
				// ~ .text h4{
				// 	text-decoration: underline;
				// }

				~ .text .arrow-link{
					background-color: $white;
					border-color: $white;
					margin-left: 2rem;
				}
			}
		}

		img{
			aspect-ratio: 418/287;
			object-fit: cover;
			object-position: 50% 50%;
			width: 100%;
		}

		.text{
			padding: 2.4rem 2.3rem 3.2rem 2.3rem;

			@media (max-width: $md){
				padding: 2rem 2.5rem 1.9rem 2.5rem;
			}
		}

		h4{
			margin-bottom: -.01em;

			@media (max-width: $md){
				margin-bottom: .1em;
				letter-spacing: -.02em;
				@include font-size(24);
				@include line-height(24,28);
			}
		}

		p.large{
			margin-bottom: .5em;
			color: $form;

			@media (max-width: $md){
				margin-bottom: 0.9em;
			}
		}

		.arrow-link{
			aspect-ratio: 1/1;
			width: 5rem;
			border-radius: 5rem;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: $pink;
			border: .2rem solid $pink;
			transition: $time;
			margin-top: 2.6rem;

			@media (max-width: $md){
				width: 4rem;
			}

			svg{
				aspect-ratio: 33/14.73;
				width: 3.3rem;

				@media (max-width: $md){
					width: 2.8rem;
				}

				*{
					transition: $time;
				}
			}
		}
	}
}

.things-we-can-do-for-you-section{

	.container{
		max-width: 138rem;
	}

	.row{
		padding-top: 4rem;
		padding-bottom: 14.7rem;
		align-items: center;

		@media (max-width: $md){
			padding-top: 3.9rem;
			padding-bottom: 11.1rem;
		}
	}

	.text-con{
		text-align: center;
		margin-bottom: 5rem;

		@media (max-width: $md){
			margin-bottom: 3.3rem;
		}

		.text{
			width: 66rem;
			margin-left: auto;
			margin-right: auto;

			@media (max-width: $md){
				width: 28rem;

				h3{
					letter-spacing: -.02em;
					@include font-size(24);
					@include line-height(24,28);
				}
			}
		}
	}

	.things-slide{
		width: calc(100% + .75rem + .75rem);
		max-width: calc(100% + .75rem + .75rem);
		margin-left: -.75rem;

		.swiper-wrapper-con{
			overflow: visible!important;
		}

		.swiper-slide{
			padding: 0 .75rem;

			img{
				width: 100%;
				border-radius: 2.5rem;
				aspect-ratio: 272/325;
				object-fit: cover;
				object-position: 50% 50%;
				margin-bottom: 1.6rem;
			}

			p.large{

				@media (max-width: $md){
					@include font-size(24);
					@include line-height(24,31);
				}
			}
		}
	}
}

.text-with-form-section{

	.container{
		max-width: 101.5rem;
	}

	.row{
		padding-top: 11.6rem;
		padding-bottom: 8.7rem;

		@media (max-width: $md){
			padding-top: 12.5rem;
			padding-bottom: 4.8rem;
		}
	}

	.text-con{
		text-align: center;
		margin-bottom: 7.4rem;

		@media (max-width: $md){
			margin-bottom: 6.4rem;
		}

		.text{
			width: 60rem;
			margin-left: auto;
			margin-right: auto;

			@media (max-width: $md){
				width: 34rem;

				h4{
					@include font-size(20);
					@include line-height(20,22);
				}
			}
		}

		p{
			margin-left: auto;
			margin-right: auto;
			width: 58rem;
		}
	}

	.gform_confirmation_message{
		text-align: center;
	}

	.form-con{

		@media (min-width: $md + 1){
			padding-left: 5.8rem;
			padding-right: 5.8rem;
		}
	}

	select{
		background-size: 2.4rem auto;
		background-image: url(../images/select-big.svg);
		background-position: right 1.15rem top 50%;
	}

	// *[type="submit"], 
	// .submit-con [type="submit"]{
	// 	background-color: $blue;
	// 	border-color: $blue;

	// 	&:hover,
	// 	&:focus-visible{
	// 		background-color: $black;
	// 		border-color: $black;
	// 		color: $blue!important;
	// 	}
	// }
}

.events-archive-section{
	background-image: url(../images/events-left.svg), url(../images/events-right.svg);
	background-repeat: no-repeat;
	background-position: left 0rem top 11.8rem, right 0rem top 13rem;
	background-size: 24.4rem auto, 32rem auto;

	@media (max-width: $md){
		background-image: url(../images/events-left-m.svg), url(../images/events-right-m.svg);
		background-position: left 0rem top 8.1rem, right 0rem top 15.2rem;
		background-size: 8.4rem auto, 7.5rem auto;
	}

	.container{
		max-width: 115rem;
	}

	.row{
		padding-top: 18.6rem;
		padding-bottom: 12.9rem;

		@media (max-width: $md){
			padding-top: 15.1rem;
			padding-bottom: 7.8rem;
		}
	}

	.filter-con{
		margin-bottom: 2.6rem;

		.facetwp-type-radio{
			margin-left: -.5rem;
			width: calc(100% + .5rem + .5rem);
			max-width: calc(100% + .5rem + .5rem);

			.facetwp-radio{

				&.checked{
					background-color: $yellow;
					border-color: $yellow;
				}
			}
		}
	}

	.text-con{
		text-align: center;
		margin-bottom: 7.7rem;

		@media (max-width: $md){
			margin-bottom: 2.7rem;
		}

		.text{
			width: 84rem;
			margin-left: auto;
			margin-right: auto;

			svg{
				width: 41.7rem;
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 5.5rem;

				@media (max-width: $md){
					width: 20.1rem;
					margin-bottom: 10.5rem;
				}
			}

			@media (max-width: $md){
				
				h2{
					@include font-size(36);
					@include line-height(36,36);
				}
			}
		}
	}
}

.e-item{
	margin-bottom: 4rem;

	@media (max-width: $md){
		margin-bottom: 2.6rem;
	}

	.inner{
		border-radius: 2.5rem;
		border: .2rem solid $black;
		background-color: $white;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		padding: 0.4rem 1rem 1.8rem 1.4rem;
		background-image: url(../images/events-corner.svg);
		background-repeat: no-repeat;
		background-size: 6.5rem;
		background-position: right 1.5rem bottom 1.9rem;

		@media (max-width: $md){
			padding: 1.7rem 1rem 2.4rem 1.4rem;
			background-image: none;
		}

		img{
			aspect-ratio: 239/251;
			object-fit: cover;
			object-position: 50% 50%;
			margin-right: 3.3rem;
			width: 23.9rem;

			@media (max-width: $md){
				width: 12.2rem;
				margin-right: .7rem;
			}
		}

		.text-side{
			width: calc(100% - 23.9rem - 3.3rem - 10.5rem);
			padding-top: 2.3rem;

			@media (max-width: $md){
				width: 100%;
				order: 3;
				padding-top: .3rem;
			}

			@media (min-width: $md + 1){
				padding-right: 8rem;
			}

			h4{
				margin-bottom: .05em;

				@media (max-width: $md){
					display: none;
				}
			}

			p{

				@media (max-width: $md){
					width: 30rem;
				}

				&.large{
					margin-bottom: .5em;
					color: $form;

					@media (max-width: $md){
						display: none;
					}
				}
			}

			.btn-con{
				margin-top: 2.8rem;

				@media (max-width: $md){
					margin-top: 1.3rem;

					.btn{
						font-size: 1.3rem!important;
						padding: 0.27rem 1rem;
					}
				}
			}
		}

		.information-side{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			width: 10.5rem;
			padding-top: 2.5rem;

			@media (max-width: $md){
				padding-top: .5rem;
				width: calc(100% - 12.2rem - .7rem);
				order: 2;
			}

			.information{
				border: .1rem solid $black;
				@include font-size(14);
				padding: .45rem .7rem;
				border-radius: 1.45rem;
				margin-bottom: 1.05rem;
				background-color: white;

				@media (max-width: $md){
					@include font-size(10);
					padding: .3rem .7rem;
					margin-bottom: .5rem;
				}

				&:has(+ .information){
					margin-right: .5rem;
				}
			}

			h4{
				margin-bottom: .05em;
				margin-top: 1rem;

				@media (min-width: $md + 1){
					display: none;
				}
			}

			p{

				@media (min-width: $md + 1){
					display: none;
				}

				&.large{
					margin-bottom: .5em;
					color: $form;
				}
			}
		}
	}
}

.search-archive-section{

	@media (min-width: $md + 1){
		
		.container,
		.col-4,
		.col-12{
			padding-right: 3.15rem;
			padding-left: 3.15rem;
		}

		.row{
			margin-right: -3.15rem;
			margin-left: -3.15rem;
		}
	}

	.container{
		max-width: 142.2rem;
	}

	.row{
		padding-top: 17.4rem;
		padding-bottom: 8.7rem;

		@media (max-width: $md){
			padding-top: 10.9rem;
			padding-bottom: 4rem;
		}
	}

	.text{
		text-align: center;

		h2{
			width: 108rem;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.title-side{

		@media (max-width: $md){
			margin-bottom: 5.2rem;
		}

		h4{
			line-height: 1.4;

			@media (max-width: $md){
				line-height: 1.25;
				letter-spacing: -.02em;
				@include font-size(24);
			}
		}
	}

	.no-result{

		.inner{

		}
	}

	.container-fluid{

		.row{
			padding-top: .8rem;
			padding-bottom: 0;

			@media (max-width: $md){
				padding-top: 1.2rem;
			}
		}
	}

	.search-side{

		@media (min-width: $md + 1){
			padding-left: 1.6rem;
			padding-right: 14.5rem;
		}
	}

	.search-item{
		margin-bottom: 4rem;

		@media (max-width: $md){
			margin-bottom: 4.8rem;
		}

		&:last-of-type{
			margin-bottom: 0;

			.inner{
				border-bottom: none;
			}
		}

		.inner{
			width: 100%;
			border-bottom: .1rem solid $black;
			padding-bottom: 4rem;
			position: relative;

			@media (max-width: $md){
				padding-bottom: 3.8rem;
			}

			a.over{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border-radius: 2.5rem 2.5rem 0 0;
				z-index: 2;
				@include font-size(0);
	
				&:hover,
				&:focus-visible{
					
					// ~ .text h6,
					// ~ .text h4{
					// 	text-decoration: underline;
					// }
	
					~ h6 .arrow-link{
						background-color: $green;
						border-color: $green;

						*{
							fill: $black;
						}
					}
				}
			}

			h6{
				position: relative;
				padding-right: 6.3rem;
				display: inline-block;
				font-weight: 500;
				margin-bottom: .6em;

				@media (max-width: $md){
					@include font-size(20);
					@include line-height(20,22);
					width: 100%;
				}

				.arrow-link{
					aspect-ratio: 1/1;
					width: 3.6rem;
					border-radius: 3.6rem;
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: $black;
					border: .2rem solid $black;
					transition: $time;
					top: 50%;
					transform: translateY(-50%);
					right: 0;
					position: absolute;
		
					svg{
						aspect-ratio: 33/14.73;
						width: 2rem;
		
						*{
							transition: $time;
							fill: $white;
						}
					}
				}
			}
		}
	}
}

.gradient-text-with-image-section{
	background: linear-gradient(180deg, rgba(237,237,237,1) 0%, rgba(36,156,253,1) 100%);

	.container{
		max-width: 92rem;
	}

	.row{
		padding-top: 6rem;
		padding-bottom: 10rem;
		align-items: center;

		@media (max-width: $md){
			padding-top: 3.7rem;
			padding-bottom: 7.1rem;
			text-align: center;
		}
	}

	.text-side{
		
		@media (min-width: $md + 1){
			flex: 0 0 71.5%;
			max-width: 71.5%;
		}

		@media (max-width: $md){
			order: 2;
		}
	}

	p.large{
		font-weight: 500;

		@media (min-width: $md + 1){
			margin-bottom: .45em;
		}
	}

	.image-side{
		
		@media (min-width: $md + 1){
			flex: 0 0 28.5%;
			max-width: 28.5%;
		}

		@media (max-width: $md){
			margin-bottom: 3rem;
		}

		img{
			width: 100%;

			@media (max-width: $md){
				width: 14.1rem;
				margin: 0 auto;
			}
		}
	}

	.btn-con{
		margin-top: 2.5rem;

		@media (max-width: $md){
			justify-content: center;
			margin-top: 1.6rem;
		}
	}

	.btn{
		background-color: $black;
		border-color: $black;
		color: $white!important;

		&:hover,
		&:focus-visible{
			background-color: $yellow;
			border-color: $yellow;
			color: $black!important;
		}
	}
}

.gradient-text-with-image-2-section{
	background: linear-gradient(180deg, rgba(237,237,237,1) 0%, rgba(36,156,253,1) 100%);
	background: linear-gradient(274.56deg, #16B988 0.75%, #FFFFFF 24.81%, #FFFFFF 40.23%, #FFC700 98.22%);

	.container{
		max-width: 115rem;
	}

	.row{
		padding-top: 5.3rem;
		padding-bottom: 4.3rem;
		align-items: flex-start;

		@media (max-width: $md){
			padding-top: 3.7rem;
			padding-bottom: 4.6rem;
			text-align: center;
		}
	}

	.text-side{
		align-self: center;
		
		@media (min-width: $md + 1){
			flex: 0 0 54.3%;
			max-width: 54.3%;
			padding-right: 15rem;
			padding-top: 1.5rem;
		}

		@media (max-width: $md){
			order: 2;
			padding-left: 0;
			padding-right: 0;
		}
	}

	h2{
		font-weight: 500;
		margin-bottom: .6em;

		@media (max-width: $md){
			margin-bottom: .35em;
		}
	}

	.image-side{
		
		@media (min-width: $md + 1){
			flex: 0 0 45.7%;
			max-width: 45.7%;
		}

		@media (max-width: $md){
			margin-bottom: 2.3rem;
		}

		img{
			width: 100%;

			@media (min-width: $md + 1){
				width: calc(100% + ((100dvw - 113rem)/2));
				max-width: calc(100% + ((100dvw - 113rem)/2));
				margin-top: -7.8rem;
			}

			@media (min-width: 2560px + 1){
				max-height: 61rem;
				width: auto;
				margin-right: calc((100dvw - 113rem)/-2);
				float: right;
			}

			@media (max-width: $md){
				margin-left: -2rem;
				width: calc(100% + 4rem);
				max-width: calc(100% + 4rem);
				margin-top: -3.8rem;
			}
		}
	}

	.btn-con{
		margin-top: 4.2rem;

		@media (max-width: $md){
			justify-content: center;
			margin-top: 3rem;
		}
	}

	.btn{
		background-color: $green;
		border-color: $green;
		color: $black!important;

		&:hover,
		&:focus-visible{
			background-color: $black;
			border-color: $black;
			color: $white!important;
		}
	}
}


.knowledge-bank-section{

	@media (min-width: $md + 1){
		
		.container,
		.col-4,
		.col-12{
			padding-right: 4rem;
			padding-left: 4rem;
		}

		.row{
			margin-right: -4rem;
			margin-left: -4rem;
		}
	}

	.container{
		max-width: 144rem;
	}

	.row{
		padding-top: 18.5rem;
		padding-bottom: 10.6rem;

		@media (max-width: $md){
			padding-top: 11.8rem;
			padding-bottom: 8.1rem;
		}
	}

	.text{
		text-align: center;
		margin-bottom: 4.4rem;

		@media (max-width: $md){
			margin-bottom: 3.4rem;
		}

		h2{
			width: 83rem;
			margin-left: auto;
			margin-right: auto;

			@media (max-width: $md){
				@include font-size(36);
				@include line-height(36,36);
			}
		}
	}

	.filter-con{
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: 6.5rem;

		@media (max-width: $md){
			justify-content: flex-start;
			margin-bottom: 1.7rem;
		}

		small{
			width: auto;
			margin-right: 2rem;
			font-weight: 500;
			margin-bottom: 1.6rem;

			@media (max-width: $md){
				@include font-size(14);
				width: 100%;
				margin-bottom: .9rem;
			}
		}

		.facetwp-facet{
			margin-bottom: 1.6rem;

			@media (max-width: $md){
				
				&:nth-of-type(1){
					width: 9.3rem;
				}

				&:nth-of-type(2){
					width: 9.7rem;
				}

				&:nth-of-type(3){
					width: 13.1rem;
				}
			}

			&:has(+ .facetwp-facet){
				margin-right: 1.6rem;
			}
		}

		select{
			@include font-size(14);
			height: 3.4rem;
			line-height: 3.4rem - .2rem - .2rem;
			padding-right: 2.9rem;
			padding-left: 1rem;
			background-size: 1.12901rem auto;
			background-position: right 1.05rem top 50%;

			&.facetwp-dropdown{

				&:has(select.filler){
					opacity: 0;
				}

				+ select.filler{
					width: auto;

				}
			}

			&.filler{
				pointer-events: none;
				position: absolute;
				top: 0;
				opacity: 0;
				visibility: hidden;
			}
		}
	}

	.facetwp-template{
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}

	.facetwp-facet-pages{
		margin-top: 3.9rem;

		@media (max-width: $md){
			margin-top: 6rem;
		}
	}
}

.k-item{
	margin-bottom: 4.3rem;

	@media (max-width: $md){
		margin-bottom: 3.1rem;
	}

	.inner{
		position: relative;

		a.over{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 2.5rem 2.5rem 0 0;
			z-index: 2;
			@include font-size(0);

			&:hover,
			&:focus-visible{
				
				~ h6{
					text-decoration: underline;
				}
			}
		}

		video,
		img{
			border-radius: 2.5rem;
			margin-bottom: 1.9rem;
			aspect-ratio: 400/280;
			object-fit: cover;
			object-position: 50% 50%;
			width: 100%;

			@media (max-width: $md){
				aspect-ratio: 349/280;
				margin-bottom: 2rem;
			}
		}

		h6{
			margin-bottom: .9rem;

			@media (max-width: $md){
				margin-bottom: .8rem;
				@include font-size(18);
			}
		}


		p{
			color: $form;
		}

		.cats{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			margin-top: 1.3rem;

			@media (max-width: $md){
				margin-top: 1rem;
			}

			.cat{
				border: .1rem solid $grey;
				background-color: $grey;
				@include font-size(12);
				padding: .45rem .7rem;
				border-radius: .4rem;
				margin-bottom: 1rem;
				color: $black;
				text-transform: capitalize;

				// @media (max-width: $md){
				// 	@include font-size(10);
				// }

				&.events{
					background-color: $yellow;
					border-color: $yellow;
				}

				&.blogs{
					background-color: $pink;
					border-color: $pink;
				}

				&.webinars{
					background-color: $blue;
					border-color: $blue;
				}

				&.resources{
					background-color: $purple;
					border-color: $purple;
				}

				&.news{
					background-color: $green;
					border-color: $green;
				}

				&:has(+ .cat){
					margin-right: 1rem;
				}
			}
		}
	}
}

.eleven-segments-section{

	.container{
		max-width: 124.2rem;
	}

	.row{
		padding-top: 6.1rem;
		padding-bottom: 5.4rem;
		align-items: center;

		@media (max-width: $md){
			padding-top: 4rem;
			padding-bottom: 6.1rem;
		}
	}

	.text-side{
		
		@media (min-width: $md + 1){
			flex: 0 0 47%;
			max-width: 47%;
			padding-left: 6.4rem;
		}

		svg{
			width: 32.1rem;
			margin-bottom: 2.3rem;

			@media (max-width: $md){
				width: 20.6rem;
				margin-bottom: 1.1rem;
			}
		}

		h3{
			margin-bottom: .45em;

			@media (max-width: $md){
				margin-bottom: .65em;
			}
		}

		.btn-con{
			margin-top: 2.3rem;

			@media (max-width: $md){
				margin-top: 1.2rem;
			}

			.btn{
				background-color: $yellow;
				border-color: $yellow;

				&:hover,
				&:focus-visible{
					background-color: $black;
					border-color: $black;
				}
			}
		}
	}

	.image-side{
		
		@media (min-width: $md + 1){
			flex: 0 0 53%;
			max-width: 53%;
		}

		img{
			width: 100%;

			@media (min-width: $md + 1){
				width: 104%;
				max-width: 104%;
				margin-left: -4%;
			}
		}
	}
}

.our-commitments-section{

	.container{
		max-width: 97rem;
	}

	.row{
		padding-top: 8rem;
		padding-bottom: 2.7rem;

		@media (max-width: $md){
			padding-top: 4rem;
			padding-bottom: 2.1rem;
		}
	}

	.title-con{
		margin-bottom: 5.9rem;
		text-align: center;

		@media (max-width: $md){
			margin-bottom: 3.1rem;

			h3{
				@include font-size(24);
				@include line-height(24,28);
			}
		}
	}

	.com-item{
		margin-bottom: 7rem;

		@media (min-width: $md + 1){
			padding-right: 4rem;
		}

		@media (max-width: $md){
			padding-right: 2rem;
		}

		.inner{

			.img-con{
				display: flex;
				justify-content: center;
				align-items: center;
			}

			img{
				// width: 20rem;
				// aspect-ratio: 1/1;
				// object-fit: cover;
				// object-position: 50% 50%;
				width: 100%;
				margin-bottom: 3.2rem;
			}
		}
	}
}

.book-eventswebinars-section{

	&:has(.gform_confirmation_message){

		.text-con{
			display: none;
		}

		.form-con{

			.calendar-con{
				display: block;
			}
		}
	}

	.container{
		max-width: 92rem;
	}

	.row{
		padding-top: 5.9rem;
		padding-bottom: 14rem;

		@media (max-width: $md){
			padding-top: 4.2rem;
			padding-bottom: 7.7rem;
		}
	}

	.text-con{
		margin-bottom: 8.5rem;

		@media (min-width: $md + 1){
			padding-right: 4rem;
		}

		@media (max-width: $md){
			margin-bottom: 4.8rem;
			
			h4{
				letter-spacing: -.02em;
				@include font-size(24);
				@include line-height(24,28);
			}
		}
	}

	.form-con{

		.gform_confirmation_message{
			width: 42rem;
			padding-top: 34.2rem;
			background-image: url(../images/done.svg);
			background-repeat: no-repeat;
			background-size: 16.4rem 16.4rem;
			background-position: 50% 15.2rem;
			margin: 0 auto;
			text-align: center;
		}

		.gform_heading{
	
			p{
				break-inside: avoid;

				@media (min-width: $md + 1){
					column-count: 2;
					width: 100%;
				}
			}
		}

		.calendar-con{
			margin: 0 auto;
			display: none;
			margin-top: 3.7rem;
			width: 22rem;
			margin-bottom: 7.5rem;

			.add{
				background-color: $yellow;
				color: $black;
				padding: 1.2rem 4.1rem 1.2rem 1.9rem;
				border-radius: 2.5rem;
				background-image: url(../images/select-icon.svg);
				background-repeat: no-repeat;
				background-position: right 2.1rem top 50%;
				position: relative;
				z-index: 2;
				font-weight: 500;
			}

			.options{
				background-color: $white;
				margin-top: -2.5rem;
				padding: 3.1rem 1.8rem 1.2rem;
				border-radius: 0 0 2.5rem 2.5rem;

				a{
					color: $black;
					display: block;
					text-decoration: none;
					padding: 0.7rem 0;
					border-bottom: .1rem solid $black;

					&:last-of-type{
						border-bottom: none;
					}
				}
			}
		}
	}	
}

.webinar-section{

	.row{
		padding-top: 6rem;
		padding-bottom: 9.2rem;
		text-align: center;

		@media (max-width: $md){
			padding-top: 4.2rem;
			padding-bottom: 7.8rem;
		}
	}

	.text-con{
		margin-bottom: 4rem;

		@media (max-width: $md){
			margin-bottom: 2rem;
			
			h4{
				letter-spacing: -.02em;
				@include font-size(24);
				@include line-height(24,28);
			}
		}
	}

	iframe,
	video{
		border-radius: 1rem;
		width: 100%;
	}
}



.hero-section.hero-section.hero-section.hero-section:has(+ .hero-section-spacer + .polices-section){

	& + .hero-section-spacer .row,
	.row{
		padding-top: 13.597rem;
		min-height: 59.7rem;

		@media (max-width: $md){
			min-height: 37.5rem;
		}

		h2{
			margin-bottom: .56em;
		}

		@media (max-width: $md){
			padding-top: 6.397rem;

			h4{
				@include font-size(13);
				@include line-height(13,16.93);
			}
		}
	}
}

.polices-section{

	.container{
		max-width: 141.8rem;
	}

	.row{
		padding-top: 7.7rem;
		padding-bottom: 11.7rem;

		@media (max-width: $md){
			padding-top: 7.8rem;
			padding-bottom: 9.9rem;
		}
	}

	@media (min-width: $md + 1){

		.container,
		.col-4,
		.col-12{
			padding-right: 2.65rem;
			padding-left: 2.65rem;
		}

		.row{
			margin-right: -2.65rem;
			margin-left: -2.65rem;
		}
	}
}

.pol-item{
	margin-bottom: 5.7rem;

	@media (max-width: $md){
		margin-bottom: 2.5rem;
	}

	@media (min-width: $md + 1){
		
		&:nth-child(3n+1):nth-last-child(-n+3),
		&:nth-child(3n+1):nth-last-child(-n+3) ~ .sec-item{
			margin-bottom: 0;
		}
	}

	.inner{
		position: relative;
		border: .2rem solid $black;
		border-radius: 2.5rem;
		overflow: hidden;

		a.over{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 2.5rem 2.5rem 0 0;
			z-index: 2;
			@include font-size(0);

			&:hover,
			&:focus-visible{
				
				// ~ .text h6,
				// ~ .text h4{
				// 	text-decoration: underline;
				// }

				~ .text .arrow-link{
					background-color: $form;
					border-color: $form;
					margin-left: 2rem;

					*{
						fill: $grey;
					}
				}
			}
		}

		img{
			aspect-ratio: 418/287;
			object-fit: cover;
			object-position: 50% 50%;
			width: 100%;

			@media (max-width: $md){
				aspect-ratio: 349/287;
			}
		}

		.text{
			padding: 2.4rem 3.3rem 3.2rem 3.3rem;

			@media (max-width: $md){
				padding: 2.3rem 1.9rem 2.3rem 1.9rem;
			}
		}

		h4{
			margin-bottom: -.01em;

			@media (max-width: $md){
				margin-bottom: .1em;
				@include font-size(20);
				@include line-height(20,22);
			}
		}

		p.large{
			margin-bottom: .5em;
			color: $form;

			@media (max-width: $md){
				margin-bottom: 0.9em;
			}
		}

		.arrow-link{
			aspect-ratio: 1/1;
			width: 5rem;
			border-radius: 5rem;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: $black;
			border: .2rem solid $black;
			transition: $time;
			margin-top: 2.6rem;

			svg{
				aspect-ratio: 33/14.73;
				width: 3.3rem;

				*{
					transition: $time;
					fill: $white;
				}
			}
		}
	}
}

.our-accreditations-section{

	@media (min-width: $md + 1){
		
		.container,
		.col-6,
		.col-12{
			padding-right: 2.1rem;
			padding-left: 2.1rem;
		}

		.row{
			margin-right: -2.1rem;
			margin-left: -2.1rem;
		}
	}

	.container{
		max-width: 130.9rem;
	}

	.row{
		padding-top: 8rem;
		padding-bottom: 2.5rem;
		text-align: center;
		justify-content: center;

		@media (max-width: $md){
			padding-top: 4.9rem;
			padding-bottom: 9.9rem;
			padding-right: 3.6rem;
			padding-left: 3.6rem;
		}
	}

	.title-con{
		margin-bottom: 4.1rem;

		@media (max-width: $md){
			margin-bottom: 4rem;

			h3{
				letter-spacing: -.02em;
				@include font-size(24);
				@include line-height(24,28);
			}
		}
	}

	.col-md-3{
		margin-bottom: 4.2rem;

		@media (max-width: $md){
			margin-bottom: 5rem;
		}

		.inner{
			width: 100%;
			border: .2rem solid $white;
			border-radius: 2.5rem;
			padding: 4rem 4rem 3.8rem;

			// @media (max-width: $md){
			// 	border-radius: 1.5rem;
			// 	padding: 1.2rem 1rem .5rem;
			// }

			.img-con,
			.text{
				display: flex;
				flex-direction: column;
				justify-content: center;
			}

			.text{
				padding-right: 1rem;
				padding-left: 1rem;
			}

			p.large{
				@include line-height(24,30);
				margin-bottom: 0;

				@media (max-width: $md){
					@include font-size(24);
					@include line-height(24,30);
				}
			}

			.img-con{
				margin-bottom: 1.5em;

				@media (max-width: $md){
					margin-bottom: 2.5em;
				}
			}

			img{
				margin: 0 auto;
			}
		}
	}
}

.thanks-section{

	.container{
		max-width: 46rem;
	}

	.row{
		padding-top: 15rem;
		padding-bottom: 15rem;
		height: 100dvh;
		align-items: center;
		text-align: center;
	}

	h2{
		margin-bottom: .45em;
	}
}

.hidden-achive{
	position: absolute;
	width: 0;
	height: 0;
	opacity: 0;
	max-width: 0;
	max-height: 0;
}
































//
//!!BLOCKS END
//

@media (min-width: $md + 1){
	
	.container{
		max-width: ($base-widthWO + $grid-gutter-widthWO) / 10 + rem;
	}

	.container,
	.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{
		padding-right: $grid-gutter-widthWO / 2 / 10 + rem;
		padding-left: $grid-gutter-widthWO / 2 / 10 + rem;
	}

	.row{
		margin-right: -$grid-gutter-widthWO / 2 / 10 + rem;
		margin-left: -$grid-gutter-widthWO / 2 / 10 + rem;
	}
}

@media (max-width: $md){

	.container,
	.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{
		padding-right: .5rem;
		padding-left: .5rem;
	}

	.container{
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.row{
		margin-right: -.5rem;
		margin-left: -.5rem;
	}
}

// @media (max-width: $sm){

// 	.container,
// 	.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{
// 		padding-right: .7rem;
// 		padding-left: .7rem;
// 	}

// 	.container{
// 		padding-right: 2.4rem;
// 		padding-left: 2.4rem;
// 	}

// 	.row{
// 		margin-right: -.7rem;
// 		margin-left: -.7rem;
// 	}
// }
