$social-size:		30;

$facebook:		#3b5999;
$twitter:		#55acee;
$linkedin:		#007bb5;
$pinterest:		#cb2027;
$instagram:		#8a3ab9;
$youtube:		#bb0000;

.social-menu{
	margin-bottom: 0;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-wrap: wrap;

	a{
		@include font-size($social-size);
		line-height: $social-size/10 + rem;
		height: $social-size/10 + rem;	
		display: block;
		text-align: center;
		color: $bronze;
		text-decoration: none;
		padding: 0;
		margin: 0 .95rem 0 0;
		display: flex;
		align-items: center;
		justify-content: center;

		&:last-of-type{
			margin-right: 0;
		}

		&:first-of-type{
			margin-left: 0;
		}
		
		&:hover,
		&:focus-visible{

			i{
				color: $yellow!important;
			}
		}

		i{
			@include font-size($social-size);
			line-height: $social-size/10 + rem;
			height: $social-size/10 + rem;
			display: block;
			transition: $time;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&.facebook{

			&:hover,
			&:focus-visible{

				i{
					color: $facebook!important;
				}
			}
		}

		&.twitter{

			&:hover,
			&:focus-visible{

				i{
					color: $twitter!important;
				}
			}
		}

		&.linkedin{

			&:hover,
			&:focus-visible{

				i{
					color: $linkedin!important;
				}
			}
		}

		&.pinterest{

			&:hover,
			&:focus-visible{

				i{
					color: $pinterest!important;
				}
			}
		}

		&.instagram{

			&:hover,
			&:focus-visible{

				i{
					color: $instagram!important;
				}
			}
		}
	}
}