 html{
	@if $scale {
		font-size: resize(10px);

		@media (min-width: $md + 1){

			@media (max-width: 2560px){
				
				&.increase-size{
					font-size: resize(11.5px);
				}
			}
		}
	} @else {
		font-size: 10px;
	}
	font-family: $main-font;
	font-weight: 400;
	margin-top: 0 !important;
	background-color: $main-bg-colour;
	overflow-x: clip;

	@media (min-width: 1440px + 1){
		font-size: 10px;

		&.increase-size{
			font-size: 11.5px;
		}
	}

	// @media (min-width: 2560px + 1){
	// 	font-size: 17.7777px;
		
	// 	&.increase-size{
	// 		font-size: 20.444355px;
	// 	}
	// }

	@media (max-width: $md){

		&.menu-open{
			overflow: hidden;
		}
	}

	@media (max-width: $md){
		font-size: 2.544529vw;

		&.increase-size{
			font-size: 2.926208vw;
		}
	}

	// &#BTT{
	// 	margin-top: 0 !important;
	// }

	&:-webkit-scrollbar{
		display: none;
	}

	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */

	&:before,
	&:after,
	*{

		&:-webkit-scrollbar {
			display: none;
		}

		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	}

	*{

		&:-webkit-scrollbar{
			display: none;
		}
	
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	
		&:before,
		&:after,
		*{
	
			&:-webkit-scrollbar {
				display: none;
			}
	
			-ms-overflow-style: none;  /* IE and Edge */
			scrollbar-width: none;  /* Firefox */
		}
	}

	@media (min-width: $md + 1){
		
		&.large-cursor{
			cursor: url(../images/cursor.svg), auto;

			*{
				cursor: url(../images/cursor.svg), auto;
			}

			a,
			button{
				cursor: url(../images/hand-pointer-solid.svg), pointer !important;
			}
		}
	}

	@media (min-width: $md + 1){
			
		&.adhd-friendly{

			.mouse-follow{
				display: block!important;
			}
		}
	}
	
	.mouse-follow{
		display: none;
		height: 20rem;
		left: 0;
		pointer-events: none;
		position: fixed;
		width: 100vw;
		z-index: 9999999999;
		transform: translateY(-50%);

		

		@media (max-width: $md){
			display: none!important;
		}

		&:after,
		&:before{
			content: "";
			background-color: rgba(0, 0, 0, .7);
			height: 100vh;
			left: 0;
			position: absolute;
			right: 0;
		}
		
		&:before{
			bottom: 100%;
		}

		&:after{
			top: 100%;
		}
	}

	
}

body{
	color: $colour;
	@include font-size(20);
	@include font-size(16);
	@include line-height(20,26);
	font-weight: 400;
	position: relative;
	overflow: clip;

	@media (max-width: $md){
		@include font-size(13);
		@include line-height(13,16.9);
	}

	&:-webkit-scrollbar{
		display: none;
	}

	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */

	&:before,
	&:after,
	*{

		&:-webkit-scrollbar {
			display: none;
		}

		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	}

	*{

		&:-webkit-scrollbar{
			display: none;
		}
	
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	
		&:before,
		&:after,
		*{
	
			&:-webkit-scrollbar {
				display: none;
			}
	
			-ms-overflow-style: none;  /* IE and Edge */
			scrollbar-width: none;  /* Firefox */
		}
	}
}

#wpadminbar{
	display: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
address,
blockquote,
pre,
table,
li{
	color: $colour;
	margin-top: 0;
	font-weight: 400;
	margin-bottom: .95em;
	@include font-size(20);
	@include font-size(16);
	@include line-height(20,26);

	@media (max-width: $md){
		@include font-size(13);
		@include line-height(13,16.9);
	}

	a{
		font-size: inherit;
		text-decoration: underline;
		font-weight: inherit;
		color: inherit;

		&:hover,
		&:focus-visible{
			color: $blue;

			.black &{
				color: $yellow;
			}
		}
	}

	strong{
		font-weight: 700;
		color: inherit;
	}

	em{
		font-style: italic;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
blockquote{
	font-family: $alt-font;
	margin-bottom: .5em;
	font-weight: 400;
	color: $black;
}

h1{
	@include font-size(128);
	@include font-size(100);
	@include line-height(128,128);
	letter-spacing: -.02em;

	@media (max-width: $md){
		@include font-size(36);
		@include line-height(36,36);
	}
}

h2{
	@include font-size(66);
	@include font-size(50);
	@include line-height(66,76);
	letter-spacing: -.02em;
	margin-bottom: 1.1em;

	@media (max-width: $md){
		@include font-size(24);
		@include line-height(24,28);
	}
}

h3{
	@include font-size(56);
	@include font-size(40);
	@include line-height(56,60);

	@media (max-width: $md){
		@include font-size(20);
		@include line-height(20,22);
	}
}	

h4{
	@include font-size(36);
	@include font-size(30);
	@include line-height(36,40);

	@media (max-width: $md){
		@include font-size(18);
		@include line-height(18,23.4);
	}
}

h5{
	@include font-size(31);
	@include font-size(28);
	@include line-height(31,35);
}

h6{
	@include font-size(24);
	@include font-size(20);
	@include line-height(24,31);
}

p{
	@include font-size(20);
	@include font-size(16);
	@include line-height(20,26);
	margin-bottom: .8em;

	@media (max-width: $md){
		@include font-size(13);
		@include line-height(13,16.9);
	}

	&.large{
		@include font-size(24);
		@include font-size(20);
		@include line-height(24,31);
		margin-bottom: .95em;

		@media (max-width: $md){
			@include font-size(13);
			@include line-height(13,16.9);
		}
	}
}

html body .main-wrapper.main-wrapper.main-wrapper.main-wrapper.main-wrapper section p{

	&:has( + ul){
		margin-bottom: 0;
	}
}

sub, 
sup{
	font-size: 25.9%;
	font-size: 51.5625%;
	font-weight: inherit;
	margin-left: -.1em;
}

sup{
	top: -.71em;

	// @media (max-width: $md){
	// 	top: -.11em;
	// 	font-size: 91.5625%;
	// }
}

sub{
	bottom: .3em;
}

small{
	font-size: 70%;
}

button{
	box-shadow: none;
	background-color: transparent;
	border: none;
	padding: 0;
}

button,
a{
	font-size: inherit;
	color: $colour;
	font-weight: 400;
	transition: $time;
	cursor: pointer;
	text-decoration: underline;
	text-underline-position: under;
	text-underline-offset: -.08em;

	&:before,
	&:after,
	*:before,
	*:after,
	*{
		transition: $time;
	}

	&:hover,
	&:focus-visible{
		//color: $green;
	}
}

button{
	box-shadow: none;
	background-color: transparent;
	border: none;
	padding: 0;
}

mark{
	background-color: $yellow;
	text-transform: uppercase;
	padding: .71em 1.1em;
	display: inline-block;

	@media (max-width: $md){
		padding: .76em 1.15em;
	}

	strong{
		color: $black;
	}
}

strong{
	font-weight: 700;
}

blockquote{
	@include font-size(60);
	@include line-height(60,60);
	margin: 0 0 1.34em;
	text-transform: none;
	padding: 0;
	font-style: normal;
	color: $colour;
	position: relative;
	z-index: 1;
	font-family: $main-font;
	font-weight: 400;

	@media (max-width: $md){
		@include font-size(20);
		@include line-height(24,24);
	}

	&:has(~ .by){
		margin: 0 0 1em;

		@media (max-width: $md){
			margin: 0 0 1.5em;
		}
	}

	&:before{
		content: '“';
	}

	&:after{
		content: '”';
	}

	~ .by{
		@include font-size(20);
		@include font-size(16);
		@include line-height(20,26);
		text-align: center;
		text-decoration: underline;
		font-weight: 400;
		display: flex;
		align-items: center;

		@media (max-width: $md){
			@include font-size(13);
			@include line-height(13,16.9);
		}
	}

	p{
		font-size: inherit!important;
		line-height: inherit!important;
		font-family: inherit!important;
		font-weight: inherit!important;
		margin-bottom: 0!important;
		letter-spacing: inherit!important;
		display: inline;
	}
}

hr{
	border: 0;
	width: 100%;
	height: .1rem;
	min-height: 1px;
	background-color: currentColor;
	margin: 1.7rem 0 2.7rem;

	@media (max-width: $lg){
		margin: .8rem 0 .8rem;
	}

	&:last-child{
		margin-bottom: 0;
	}
}

// br{

// 	@media (max-width: $md){
// 		display: none;
// 	}
// }

address{
	font-style: normal;
	@include font-size(20);
	@include font-size(16);
	@include line-height(20,26);
	color: $colour;
	margin-bottom: 1.5em;

	@media (max-width: $md){
		@include font-size(13);
		@include line-height(13,16.9);
	}
}

ol,
ul,
dl{
	margin: 0;
	padding-left: 0;
	list-style-position: inside;
	font-size: 0;
	margin-bottom: 1.55em;

	li,
	dt,
	dd{
		@include font-size(20);
		@include font-size(16);
		@include line-height(20,26);
		margin-bottom: 0;

		@media (max-width: $md){
			@include font-size(13);
			@include line-height(13,16.9);
		}

		&.large{
			@include font-size(24);
			@include line-height(24,31);

			@media (max-width: $md){
				@include font-size(13);
				@include line-height(13,16.9);
			}
		}

		a{
			font-size: inherit;
		}
	}

	dd{
		margin-bottom: 1rem;
	}
}

.acf_content ol,
.acf_content ul,
.acf_content dl,
main ol,
main ul,
main dl{
	@include font-size(20);
	@include font-size(16);
	@include line-height(20,26);
	margin-bottom: 1.55em;

	@media (max-width: $md){
		@include font-size(13);
		@include line-height(13,16.9);
	}
}

ul{
	list-style-position: inside;
	padding-left: 0;

	li{
		padding-left: 1.4em;
		margin-bottom: 0em;
		list-style: none;
		position: relative;

		&:before{
			content: '';
			position: absolute;
			left: .5em;
			top: .55em;
			width: .26em;
			aspect-ratio: 1/1;
			border-radius: 50%;
			background-color: currentColor;
		}

		ul{
			margin-top: 1em;
			margin-bottom: 1em;

			li{

				// &:before{
				// 	border-radius: 0;
				// 	height: .1em;
				// 	top: .75em;
				// }
			}
		}
	}
}

ol:not(.pagination){
	list-style-position: outside;
	padding-left: 0;
	counter-reset: section;

	li{
		list-style: none;
		position: relative;
		padding-left: 1.05em;
		margin-bottom: 0em;

		&:nth-of-type(1n + 10){

			&:before{
				content: counter(section)". ";
			}
		}

		&:before{
			counter-increment: section;
			content: counter(section)". ";
			min-width: 1em;
			display: inline-block;
			position: absolute;
			top: 0em;
			left: .1em;
			font-size: inherit;
			line-height: inherit;
			color: currentColor;

			@media (max-width: $md){
				left: 0;
			}
		}

		ol{
			margin-top: 1em;
			margin-bottom: 1em;

			li{
				padding-left: 2.3em;

				&:before{
					content: counter(section , lower-alpha)". ";
					left: .1em;
				}
			}
		}
	}
}

.wpml-ls ul,
ul.wc-item-meta,
ul.order_details,
ul.filter-items,
ul.small-footer-menu,
ul.woocommerce-PaymentMethods,
ul.woocommerce-shipping-methods,
ul.woocommerce-order-overview,
ul.wc_payment_methods,
ul.select2-results__options,
.woocommerce-MyAccount-navigation ul,
ul.woocommerce-error,
ul.tabs,
ul.small-menu,
ul.main-menu,
ul.footer-menu,
ul.sub-menu,
ul.g_fields,
ul.social-menu,
ul.flex,
ul.slick{
	list-style: none;
	padding-left: 0;
	padding-top: 0;

	> li{
		padding-left: 0;
		margin-bottom: 0;

		&:before,
		&:after{
			display: none;
		}
	}
}

html body table:not(.ui-datepicker-calendar){
	margin-bottom: 1.77778em;
	border: none;
	width: 100%!important;
	background-color: transparent;
	text-align: left;
	table-layout: fixed;
	@include font-size(20);
	@include font-size(16);
	@include line-height(20,26);

	@media (max-width: $md){
		@include font-size(13);
		@include line-height(13,16.9);
	}
	
	*{
		vertical-align: top;
	}

	&:not(:has(thead)) tbody tr:first-of-type,
	thead{

		td,
		th{
			padding: 1.1rem 1.5rem;
			border-top: .1rem solid $colour;
			border-bottom: .1rem solid $colour;
			border-left: .1rem solid $colour;
			font-family: $alt-font;
			color: $colour;
			text-transform: uppercase;
			font-weight: 400;

			@media (max-width: $md){
				padding: 1rem 1rem;
			}

			&:last-of-type{
				border-right: .1rem solid $colour;
			}
		}
	}

	tbody{

		td{
			padding: 1.55rem 1.5rem;
			border-bottom: .1rem solid $colour;
			border-left: .1rem solid $colour;

			@media (max-width: $md){
				padding: 1rem 1rem;
			}

			&:last-of-type{
				border-right: .1rem solid $colour;
			}
		}
	}
}

.wp-caption{

	.wp-caption-text{
		@include font-size(14);
		@include line-height(14,16);
		font-weight: 400;
		color: $colour;
		
		&:last-of-type:last-of-type{
			margin-bottom: 0;
			padding-top: 1.1rem;

			@media (max-width: $md){
				padding-top: .3rem;
			}
		}
	}
}

svg,
p.p-img a,
p.p-img img,
.wp-caption,
video,
img{
	max-width: 100%;
	width: auto;
	height: auto;
	display: block;

	&.alignnone{
		margin: 2.5em 0 1.2em;
	}

	&.alignright {
		float:right;
		margin: 2.5em 0 1.2em 1.2em;
	}

	&.alignleft {
		float:left;
		margin: 2.5em 1.2em 1.2em 0;
	}

	&.aligncenter {
		clear: both;
		display: block;
		margin: 2.5em auto 1.2em;
	}
}

a img.alignright {
	float:right;
	margin: 2.5em 0 1.2em 1.2em;
}

a img.alignleft {
	float:left;
	margin: 2.5em 1.2em 1.2em 0;
}

a img.aligncenter {
	clear: both;
	display: block;
	margin: 2.5em auto 1.2em;
}

::selection {
	background: darken($colour, 5%);
	color: $white;
}

::-moz-selection {
	background: darken($colour, 5%);
	color: $white;
}

.black{

	::selection {
		background: $blue;
		color: $black;
	}

	::-moz-selection {
		background: $blue;
		color: $black;
	}
}

.fieldset{
	margin-bottom: 0;

	.field{
		position: relative;
		padding-bottom: 1px;
	}

	div.mage-error[generated]{
		position: absolute;
		bottom: 5px;
	}
}

// .wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid:focus-visible,
// input:focus-visible,
// textarea:focus-visible,
// select:focus-visible,
// input[name][name][name]:focus-visible,
// textarea[name][name][name]:focus-visible,
// select[name][name][name]:focus-visible{
// 	border-color: $green!important;
// }

.wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid:focus-visible,
input.mage-error:focus-visible,
select.mage-error:focus-visible,
textarea.mage-error:focus-visible,
.wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid,
li.gfield.error input,
.gfield_error input,
.gfield_error select,
.gfield_error .dk-select,
.gfield_error textarea,
input.mage-error,
select.mage-error,
textarea.mage-error{
	border-color: $red!important;
}

div.mage-error[generated] {
	color: $red;
	position: absolute;
	margin-top: 4px;
	line-height: 1.1em;
	padding-top: 2px;
	margin-left: 0px;
	text-align: left;
}

pre{
	font-size: resize(10px);
	@include font-size(10);
}

.main-wrapper{
	padding-top: 1px;
	margin-top: -1px;
	padding-bottom: 0;
	width: 100%;
	width: 100dvw;

	section{
		overflow: hidden;
		overflow: clip;
	}
}

.inner{
	position: relative;
}

img.full-bg,
.full-bg{
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
}

img.full-bg{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: 50% 50%;
}