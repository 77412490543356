.pagination.pagination.pagination{
	margin-bottom: 0;
	vertical-align: middle;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 1.1rem;
	overflow: hidden;
	position: relative;

	.prev,
	.pagination-mid{
		position: relative;
	} 

	.pagination-mid{
		display: flex;
		justify-content: center;
		align-items: center;
	}

	div{
		text-align: center;
		width: auto;
		height: 5rem;
		@include font-size(20);
		@include line-height(20,26);
		position: relative;
		z-index: 1;

		&:not(.prev):not(.next){
			margin: 0 .1em;

			&.active{
				pointer-events: none;

				a{
					color: $colour;
					text-decoration: none;
					font-weight: 500;
				}
			}

			span,
			a{
				@include font-size(20);
				@include line-height(20,50);
				display: block;
				color: $colour;
				font-weight: 500;
				text-decoration: none;
				width: 5rem;
				height: 5rem;
				border-radius: 5rem;
				text-align: center;
				font-family: $main-font;
				background-color: transparent;
				padding: .1rem 0rem;
				margin: 0 .1rem;

				@media (max-width: $md){
					@include font-size(14);
					width: 2.4rem;
					height: 2.4rem;
					line-height: 2.3rem;
				}

				&:hover,
				&:focus-visible{
					background-color: $grey;
				}
			}

			&.ellipse{
				color: $colour;
			}
		}

		&.next,
		&.prev{

			span,
			a{
				@include font-size(18);
				width: 3.2rem;
				height: 3.2rem;
				display: flex;
				justify-content: center;
				align-items: center;
				text-decoration: none;
				background-color: transparent;
				border-radius: 50% 0 50% 50%;

				@media (max-width: $md){
					width: 2.4rem;
					height: 2.4rem;
				}

				svg{
					width: .8rem;

					@media (max-width: $md){
						width: .6rem;
					}

					path{
						fill: $white;
					}
				}

				&:hover,
				&:focus-visible{
					background-color: $grey;
				}
			}

			span{
				pointer-events: none;
			}
		}

		&.next{
			margin-left: .35em;

			@media (max-width: $md){
				margin-left: .15em;
			}
		}

		&.prev{
			margin-right: .35em;

			@media (max-width: $md){
				margin-right: .15em;
			}
		}
	}
}


.facetwp-type-pager{
	width: 100%;
}

.facetwp-pager{
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	.facetwp-page:not(.next):not(.prev){
		@include font-size(20);
		display: inline-block;
		text-decoration: none;
		padding: .1rem 0rem;
		margin: 0 .1rem;
		width: 5rem;
		height: 5rem;
		line-height: 5rem;
		border-radius: 5rem;
		text-align: center;
		font-weight: 500;

		@media (max-width: $md){
			@include font-size(16);
		}

		&:hover,
		&:focus-visible{
			background-color: $yellow;
		}

		&.active{
			pointer-events: none;
			background-color: $grey;
		}
	}

	.facetwp-page.next,
	.facetwp-page.prev{
		aspect-ratio: 33.3/14.728;
		width: 3.33rem;
		@include font-size(0);
		padding: 0;

		svg{
			width: 100%;
			

			*{
				transition: $time;
			}
		}

		&:hover,
		&:focus-visible{
			
			svg{

				*{
					fill: $yellow;
				}
			}
		}
	}

	.facetwp-page.next{
		margin-left: .5rem;
	}

	.facetwp-page.prev{
		margin-right: .5rem;
	}
}